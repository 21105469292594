import { Component, OnInit } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { Coursesinterested } from '../user1'
import { AttemptHistory } from '../user1'

import { Observable } from 'rxjs'
import { Data, Router } from '@angular/router';
import { MatSelectModule } from '@angular/material/select'
import { FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { NotificationService } from "../notification.service";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  isVisible:boolean;
  isExperience:boolean;
   selected: any;
   courses: any;
   coursesinterested: Coursesinterested;
   UserId: any
     
  form: FormGroup;
  websiteList: any = [
    { id: 1, name: 'Pressure Vessel Design & PvElite training - Basic' },
    { id: 2, name: 'Pressure Vessel Design & PvElite training - Advance' },
    { id: 3, name: 'Mechanical Design of Shell & Tube Heat Exchanger as per ASME & TEMA - Basic' },
    { id: 4, name: 'Mechanical Design of Shell & Tube Heat Exchanger as per ASME & TEMA - Advance' },
    { id: 5, name: 'PvElite software course for Pressure vessel & Heat Exchanger - Basic' },
    { id: 6, name: 'PvElite software course for Pressure vessel & Heat Exchanger - Advance' },
    { id: 7, name: 'Not Interested' },
    { id: 8, name: 'Not Now' },
  ];

  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router, private formBuilder: FormBuilder,protected _notificationSvc: NotificationService) {
    this.form = this.formBuilder.group({
      website: this.formBuilder.array([], [Validators.required])
    })
    this.coursesinterested = new Coursesinterested();
    this.selected = "";
    this.UserId = localStorage.getItem('dataSource');
   }
  yes() {
    this.isVisible = true;
    this.isExperience = true;
  }
  no() {
    this.isVisible = false;
    this.isExperience = false;
  
  }
//   onNextcourse(){
//     this.router.navigate(['/thank-you']);
// }
  onCheckboxChange(e) {
  
    const website: FormArray = this.form.get('website') as FormArray;

  
    if (e.target.checked) {
      website.push(new FormControl(e.target.value));
      this.selected = e.target.value + "," + this.selected
    } else {
       const index = website.controls.findIndex(x => x.value === e.target.value);
       website.removeAt(index);
    }
  }
  alertWithSuccess(){
    Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
  alertWithError(){
    Swal.fire('Oops...', 'Something Went Wrong! Please try again.', 'error')
  }
  sendInfo() {
    this._notificationSvc.info('Hello World', 'This is an information', 5000);
  }

  sendSuccess() {
    this._notificationSvc.success('Hello World','This is a success !');
  }

  submit(){
    this.coursesinterested.Courses = this.selected
    this.coursesinterested.UserId = this.UserId;
    console.log(this.form.value);
    console.log(this.coursesinterested);
    this.service.AddCourses(this.coursesinterested).subscribe((resp) => {
      if (resp == "Failed") {
        // alert("Something went wrong! Please try again.");
        this.alertWithError();
      } else {
        //  alert("Saved succesfully.");
        this.alertWithSuccess();
         this.router.navigate(['/thank-you']);
        // this.router.navigateByUrl("/Result-Certi/" + this.UserId);
        
      }
    });
  }
  ngOnInit() {
  }

}
