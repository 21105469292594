
<div class="conatainer-fluid">
    <div class="row" style="justify-content: center;">
        <button (click)="pdfpage()" id="btnprint">Get Your Certificate</button>
    </div>
    <div class="row certi-row">
      <div id="contentToConvert">
        <section class="section-certificate" >
            <br>
            <!-- <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 text-center">
        <img src="../../assets/images/LOGO-solutions.png" class="logo-img" />
    </div> -->
            <h2 class="certi">CERTIFICATE OF PARTICIPATION</h2>


            <h4 class="small-head">This certifies that</h4>
            <h2 class="name">{{resultCerti.OnlineExamForm.FName}} 
                {{resultCerti.OnlineExamForm.LName}}</h2>
            <h4 class="medium">Has Successfully Attended</h4>
            
            <p class="coursehead"><b>Free online test on</b></p>
                <p class="coursehead"><b><span class="course">Basics of Pressure Vessel Design</span> and has scored <span class="marks">{{marks}}/100</span></b></p>
            <!-- <h4 class="medium">In test during 2021-2022</h4> -->
            <div class="row img-row">
                <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 text-center">
                    <img src="../../assets/images/line.png" class="line-img" />
                </div>
            </div>
            <br>
            <h4 class="org-name">Organized by <span>"Express Engineering Solutions, Pune"</span></h4>
            <br>
            <!-- <div class="container"> -->
            <!-- <div class="row icons-row">
                
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                    <img src="../../assets/images/CERTIFIED.png" class="icon-img certi-icon" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                    <img src="../../assets/images/logo/logo-re.png" class="icon-img express-icon" />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center">
                    <h2></h2>
             <h5>Sachin Pol</h5>
             <span>Head-Training</span>
                </div>
            </div> -->

            <div class="row img-row">
                <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 text-center">
                    <img src="../../assets/images/2strip.jpg" class="line-img img1" />
                </div>
            </div>
            <h3 class="small-head" style="padding-top: 54px;">Date : - &nbsp;{{jstoday}}</h3>
  
            <br>
            <br>
            <br>
          
        </section>   
    </div>
    </div>
</div>