import { Component, OnInit } from '@angular/core'
import { OnlineExam,RegisterOTP } from '../user1'
import { GlobalVariable } from 'src/app/Globals'
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http'
import { CommonserviceService } from '../commonservice.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
//import sweetAlert from 'sweetalert';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';



@Component({
  selector: 'app-online-exam',
  templateUrl: './online-exam.component.html',
  styleUrls: ['./online-exam.component.css'],
})
export class OnlineExamComponent implements OnInit {

  name = 'Angular 5';
  toastRef;
  model: any = {}
  onlineExam: OnlineExam
  registerOTP:RegisterOTP
  progress: any
  loading = false
  uploadResult: any
  filesToUpload: Array<File>
  selectedFileNames: any[] = []
  // CourseList: Course[];
  CityList: any[]
  DistrictList: any[]
  StateList: any[]
  CountryList: any[]
  UniqueList: any[]
  UserList: any[];
  Email: any;
  Otp:any;
  button = 'Submit'
  isLoading = false
  flag : boolean;
  isOther : boolean;
  isCountry : boolean=false;
  isValid : boolean = false;
  nationality1 = ['Indian', 'Other']
  Categories = ['India', 'Other',]
  pincodes = ['411052', '411053', '411563', '411853', '531053']
  Cities = [
'India',
'Other',
  ]
  states = [
    'Maharashtra',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujrat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
  ]
  Districts = [
    'Pune',
    'Nagpur',
    'Nashik',
    'Thane',
    'Aurangabad',
    'Solapur',
    'Satara',
    'Dhule',
    'Sangali',
    'Amravati',
    'Kolhapur',
    'Latur',
    'Jalgoan',
    'Akola',
  ]
  Gender1 = ['Male', 'Female', 'Other']
  CourseNames = [
    'Diploma In Fire And Safety',
    'Diploma In Civil',
    'Diploma In Hotel Management And Catering Technology',
    'B.VOC In Hotel Management And Catering Technology',
    'Diploma In Naturopathy And Yogic Science',
    'Bachelor In Naturopathy And Yogic Science',
    'Diploma In Yoga Teacher',
    'Diploma In Industrial Safety',
  ]
  classes = ['1st Year', '2nd Year', '3rd Year']

  Examinations = ['10th', '12th', 'Degree']

  MobileNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$'
  isValidFormSubmitted = false

  numberPattern = '^((\\+91-?)|0)?[0-9]{10}$'

  isValidNoSubmitted = false
  EmailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
  otpStatus: any;

  save(): void {
    this.loading = true
  }

  onSubmit1() {}

  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router, private confirmDialogService: ConfirmDialogService, private toastr: ToastrService) {
    this.onlineExam = new OnlineExam();
    this.registerOTP = new RegisterOTP();
this.UniqueList = [];
this.UserList = [];
this.isValid = false;

let httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}
// this.http
// .get<any>(GlobalVariable.SERVICE_API_URL + 'City/GetAllCity', httpOptions)
// .subscribe((resp) => {
//   for (let city of resp) {
//     this.CityList.push(city.CityName)
//   }
//   console.log(this.CityList);
// });


// this.http
// .get<any>(
//   GlobalVariable.SERVICE_API_URL + 'State/GetAllState',
//   httpOptions,
// )
// .subscribe((resp) => {

//   for (let state of resp) {
//     this.StateList.push(state.StateName)
//   }
//   console.log(this.StateList);
// });

// this.http
// .get<any>(GlobalVariable.SERVICE_API_URL + 'OnlineExamForm/GetAllUniqueCode', httpOptions)
// .subscribe((resp) => {
//   for (let uniq of resp) {
//     this.UniqueList.push(uniq)
//   }
//   console.log(this.UniqueList);

// });
}
showDialog() {
  this.confirmDialogService.confirmThis("Are you sure to delete?", function () {
    // alert("Yes clicked");
  }, function () {
    // alert("No clicked");
  })
}
showToaster(){
  this.toastr.success('Some warning message', 'some title', {
    positionClass: 'toast-bottom-right'
  });

}
alertWithSuccess(){
  Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
}
alertWithSuccess1(){
  Swal.fire('Oops...', 'Something Went Wrong! Please try again.', 'error')
}
simpleAlert(){
  Swal.fire('You have already register with This Email ID.');
}
showToast=()=>{
  this.toastRef = this.toastr.show("Test",null,{
    disableTimeOut: false,
    tapToDismiss: false,
    toastClass: "toast border-red",
    closeButton: false,
    positionClass:'toast-top-right'
  });
}

removeToast = () =>{
  this.toastr.clear(this.toastRef.ToastId);
}
SearchProduct(name: string) {
  // alert(this.onlineExam.District);
  if(this.onlineExam.District == "Other"){
    this.isCountry=true;
  }
  else{
    this.isCountry=false;
  }
}
  onKeypressEvent(event){
    console.log(event.target.value);
    console.log(event.length);
    if(event.length  == 6){
      console.log(event.target.value);
    }
       }

        onNext(){
            this.router.navigate(['/otp']);
        }
  onSubmit() {

    if (this.onlineExam.FName === null || this.onlineExam.LName === null || this.onlineExam.companyname === null || this.onlineExam.District === null || this.onlineExam.Email === null ||this.onlineExam.Mobile === null || this.onlineExam.Sex === null ){
      alert('All fields are compulsary!');

    }
    // debugger;
    this.service.GetOtpDetailsByMobileNumber(this.onlineExam.Email).subscribe((resp) => {
      this.otpStatus = resp.Status;
         console.log(this.otpStatus);
        //  this.service.GetOnlineExamFormByEmailId(this.onlineExam.Email).subscribe((resp) => {
                // if(this.otpStatus == "Verified" && resp.Id != null)
                if(resp.Status == "Verified")
                {
                  //  alert('You have already register with This Email ID.');
                  this.simpleAlert();
                   this.flag = false;
                }
                else{
                  this.onlineExam.Aadhar= "896324";
                  this.onlineExam.MName="Middle Name";
                  this.onlineExam.City="default";
                  this.onlineExam.State="default";
                  this.onlineExam.companyname="default";
                  this.onlineExam.Mobile=this.onlineExam.Mobile.toString();
                  this.isLoading = true
                  this.button = 'Processing'
                    console.log(this.onlineExam);

                    // alert('submit'+ this.onlineExam.Aadhar);
                  this.service.AddOnlineExamForm(this.onlineExam).subscribe((resp) => {
                    console.log(resp)
                    if (resp == 'Failed') {
                      // alert('Something went wrong! Please try again.')
                     // this.alertWithSuccess1();

                    }
                    else {
                       GlobalVariable.UserExamId = resp;
                      localStorage.setItem('dataSource', resp);
                       GlobalVariable.FirstName = this.onlineExam.FName;
                       GlobalVariable.Lastname = this.onlineExam.LName;
                       GlobalVariable.Adhaar = this.onlineExam.Aadhar;
                       GlobalVariable.MobileNumber = this.onlineExam.Mobile;
                       GlobalVariable.Email = this.onlineExam.Email;

                      // alert(this.onlineExam.FName);

                        // alert(resp);
                        this.isLoading = false
                        this.button = 'Submit'
                        // this.alertWithSuccess();
                      // alert("Saved Successfully");
                      let num = Math.floor(100000 + Math.random() * 900000);
                      this.registerOTP.OTP = (num).toString();
                      GlobalVariable.OTP =  this.registerOTP.OTP;
                      this.registerOTP.Status = "Send";
                      this.registerOTP.Mobile= this.onlineExam.Email;
                      //  sweetAlert("Hello world!");
                         localStorage.setItem('email', this.registerOTP.Mobile);
                      this.service.AddOtpDetails(this.registerOTP).subscribe((result) => {
                        console.log(result)
                        if (result.success) {
                          console.log('success enter')
                          let httpOptions = {
                            headers: new HttpHeaders({
                              'Content-Type': 'application/json',
                            }),
                          }
                          console.log('header enter')
                          // this.http.post<RegisterOTP>(GlobalVariable.SERVICE_API_URL + 'StudentInfo/SendOTP',
                          //     this.registerOTP,
                          //     httpOptions,
                          //   ).subscribe((resp) => {
                          //        alert('Successfully Send...');
                          //   })
                            this.alertWithSuccess();
                          this.router.navigate(['/otp']);
                        } else {
                          // alert("Something went wrong! Please try again.");
                          this.alertWithSuccess1();
                        }
                      });
                      //this.alertWithSuccess();
                      // this.router.navigate(['/otp']);
                       //this.router.navigate(['/exam']);
                    }

                  });

                }

              });
            // });
    //this.onlineExam.Email =  this.Email;
    // console.log(this.UserList);
    //   this.UserList = this.UserList.filter(
    //     (result) => result.Email == this.onlineExam.Email)
    //   for (let data of this.UserList) {
    //     this.UserList.push(data);
    //   }
    //   console.log(this.UserList);
  }

  ngOnInit() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    this.CityList = [];
    this.DistrictList = [];
    this.StateList = [];

  //   this.service.GetAllCityList().subscribe((result) => {
  //     for (let city of result) {
  //       this.CityList.push(city);
  //     }
  // });


  }
}
