<!-- Sidebar Area End Here -->
<div class="dashboard-content-one">
    <!-- Breadcubs Area Start Here -->
    <div class="breadcrumbs-area">
        <!-- <h2 style="text-align: center;">Online Exam</h2> -->
    </div>
    <!-- <div class="time"> <span id="">{{clock}}</span></div> -->
    <div class="card height-auto">
        <div class="card-body">
            <!-- <div class="well col-md-12"> -->

            <div class="container">
                <div class="row" style="display: block;">
                    <!-- <button (click)="simpleAlert()">Simple Alert</button>
                <button (click)="alertWithSuccess()">Alert with Success</button>
                <button (click)="confirmBox()">Confirm Box</button> -->
                    <br />

                    <div style="text-align: center;">
                        <!-- <h3>Result: {{ answers }}/{{ questions.length }}</h3> -->
                        <h2 class="thxtext">Thank you for participating in our online Test !</h2><br>
                        <br>
                        <!-- <h6> Download our app now</h6> -->
                        <!-- <p style="text-align: justify;"> -->
                        <div class="col-md-6 text-col" style="text-align: right;">
                            <a href=" http://on-app.in/app/home?orgCode=yatgi" target="_blank" download=""><i
                                    class="fa fa-download"></i> &nbsp; Download our app now </a>
                        </div>
                        <div class="col-md-6" style="text-align: left;">
                            <!-- <a href=" http://on-app.in/app/home?orgCode=yatgi" target="_blank"> -->
                                <img class="pslogo" src="../../assets/images/logo/PS-icon.png">
                            <!-- </a> -->
                        </div>
                        <br>
                        <br>
                        <div class="col-md-6 text-col" style="text-align: right;">
                            <a href="https://youtu.be/6x4-0FOqTHg" target="_blank" download=""><i
                                    class="fa fa-eye"></i> &nbsp; See our Video </a>

                            <!-- <iframe width="420" height="315" src="https://youtu.be/embed/U66R06KYVxw" title="W3Schools Free Online Web Tutorials" frameborder="0" allowfullscreen></iframe> -->
                            <!-- <iframe width="420" height="315" src="https://www.youtube.com/embed/A6XUVjK9W4o" frameborder="0" allowfullscreen></iframe> -->
                        </div>
                        <div class="col-md-6" style="text-align: left;">
                            <a href="https://youtu.be/6x4-0FOqTHg" target="_blank" download="">
                                <img class="yt" src="../../assets/images/logo/youtube.svg">
                            </a>
                        </div>
                        <br>
                        <br>
                        <div class="row blink" style="justify-content: center;">
                            <h2 class="steps green blink-text">Cheers !!!   you can now download certificate </h2>
                        </div>
                        <div class="row">                     
                        <div class="col-md-12" style="text-align: center; margin-top: 15px;">
                            <button type="button" class="btn-fill-lg start-btn btnsubmit "
                                (click)="onNextresult()">View Certificate</button>
                        </div>
                    </div>
                    </div>
                    <br />


                </div>
            </div>
        </div>
    </div>
</div>