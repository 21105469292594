import { BrowserModule } from '@angular/platform-browser'
import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
// import { MatTableModule } from '@angular/material'

// import { MatTabsModule } from '@angular/material/tabs'


import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { OwlModule } from 'ngx-owl-carousel'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

// import { MatMenuModule } from '@angular/material/menu'
import { HttpClientModule } from '@angular/common/http'
import { RegisterComponent } from './register/register.component'


import { OnlineExamComponent } from './online-exam/online-exam.component'
import { QuizComponent } from './quiz/quiz.component'
import { CountdownModule } from 'ngx-countdown'
 //import { CountdownTimerModule } from 'ngx-countdown-timer';
// import { ApplyQuizComponent } from './apply-quiz/apply-quiz.component';

// import { AlertsModule } from 'angular-alert-module';
import {SoftNotifyModule} from 'ng-softnotify';
import { TimerComponent } from './timer/timer.component';
import { ThankYouComponent } from './thank-you/thank-you.component'
import { NgOtpInputModule } from  'ng-otp-input';
import { ResultCertiComponent } from './result-certi/result-certi.component';
import { CertificateComponent } from './certificate/certificate.component';
import { ExamResultListComponent } from './exam-result-list/exam-result-list.component';
import { OTPComponent } from './otp/otp.component';
import { CoursesComponent } from './courses/courses.component';
import { BrochuresComponent } from './brochures/brochures.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { ToastrModule } from 'ngx-toastr';

// import { MatToolbarModule, MatIconModule, MatTooltipModule, MatButtonModule } from "@angular/material";

// import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';


import { NotificationListComponent } from './notification.component';
import { NotificationService } from './notification.service';
// import { timer } from 'rxjs/observable/timer';
// import { MatTableModule } from '@angular/material'
// import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
// import { CountdownTimerModule } from 'ngx-countdown-timer';
// import { CommonModule } from '@angular/common';  
  
import {ConfirmDialogComponent} from '../app/confirm-dialog/confirm-dialog.component';  
import {ConfirmDialogService} from '../app/confirm-dialog/confirm-dialog.service'; 
// import {  ToastContainerModule } from 'ngx-toastr';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { VideoComponent } from './video/video.component';
import { TermsConditionsReportComponent } from './terms-conditions-report/terms-conditions-report.component';

@NgModule({
  declarations: [
    AppComponent,
    NotificationListComponent,
    RegisterComponent,
    ConfirmDialogComponent  ,
    OnlineExamComponent,
    QuizComponent,
    // ApplyQuizComponent,
  
    TimerComponent,
    ThankYouComponent,
    ResultCertiComponent,
    CertificateComponent,
    ExamResultListComponent,
    OTPComponent,
    CoursesComponent,
    BrochuresComponent,
    ThankyouComponent,
    CustomToastComponent,
    VideoComponent,
    TermsConditionsReportComponent,
  ],
  imports: [
    BrowserModule,
    NgOtpInputModule,
    BrowserAnimationsModule,
    HttpClientModule ,
    // ToastrModule.forRoot({
    //   toastComponent: CustomToastComponent, // added custom toast!
    // }),
    ToastrModule.forRoot(  
        {  timeOut: 2000,
          positionClass:"toast-top-right",  
          closeButton: false,  
          preventDuplicates: false
            
        }  
      ),
    //  ToastrModule.forRoot({ positionClass: 'inline' }),
    // ToastrModule.forRoot(),
    // ToastContainerModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ToastModule,
    ReactiveFormsModule,
    OwlModule,
    NgbModule,
    HttpClientModule,
    CountdownModule,
     //CountdownTimerModule.forRoot(),
    SoftNotifyModule ,
    // DropDownsModule,
    // AlertsModule.forRoot(),
    // DropDownsModule,
    // AlertsModule.forRoot(),
    RouterModule.forRoot([
    {
        path: 'Online-Exam',
        component: OnlineExamComponent,
    },
    {
        path: 'exam',
        component: QuizComponent,
    },
    //  {
    //   path: 'apply-quiz',
    //   component: ApplyQuizComponent,
    // },
    {
        path: 'thank-you',
        component: ThankYouComponent,
    },
    {
        path: 'Result-Certi',
        component: ResultCertiComponent,
    },
    {
        path: 'Certificate',
        component: CertificateComponent,
    },
    {
        path: 'Exam-Result-List',
        component: ExamResultListComponent,
    },
    {
        path: 'otp',
        component: OTPComponent,
    },
    {
        path: 'courses',
        component: CoursesComponent,
    },
    {
        path: 'brochures',
        component: BrochuresComponent,
    },
    {
        path: 'thankyou',
        component: ThankYouComponent,
    },
    {
      path: 'video',
      component: VideoComponent,
  },
  {
    path: 'terms-conditions-report',
    component: TermsConditionsReportComponent,
},
], { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
  ],
  exports: [  
    ConfirmDialogComponent  
],
entryComponents: [CustomToastComponent],
  providers: [NotificationService, ConfirmDialogService  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
