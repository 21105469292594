import { Component, OnInit } from '@angular/core';
import { Termsinfo } from '../user1'
declare const $: any;
import { GlobalVariable } from "../Globals";
import { CommonserviceService } from '../commonservice.service'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { Data, Router } from '@angular/router';
@Component({
  selector: 'app-terms-conditions-report',
  templateUrl: './terms-conditions-report.component.html',
  styleUrls: ['./terms-conditions-report.component.css']
})
export class TermsConditionsReportComponent implements OnInit {
  Termsinfo: Termsinfo;
  applicationdetailslist:any[];
  List: any[];
SearchText: string;
  constructor(private service: CommonserviceService, private http: HttpClient,private router: Router) { 
     this.Termsinfo = new Termsinfo();
      this.applicationdetailslist = [];
  }

  ngOnInit(): void {
    this.service.GetAlltermsreprot().subscribe((result) => {
      console.log(result);
  
      for(let data of result){
          this.applicationdetailslist.push(data);   
      }     
          console.log(this.applicationdetailslist);
    });
  }
  Serach() {
    this.applicationdetailslist = this.applicationdetailslist.filter(x => x.FirstName.toLowerCase().includes(this.SearchText.toLowerCase()));

  }

   Reset(){
    this.applicationdetailslist = [];

    this.service.GetAlltermsreprot().subscribe((result) => {
      for (let data of result) {
        this.applicationdetailslist.push(data);
      }
    });
  }

}
