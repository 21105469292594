import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from './Globals'
import { OnlineExam,ExamResult, AttemptHistory, RegisterOTP, Coursesinterested, SavePdf,Termsinfo } from './user1'

@Injectable({
  providedIn: 'root',
})
export class CommonserviceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json, text/plain',
    }),
  }

  constructor(private http: HttpClient) {}

  // showToast(data: string) {
  //   const toast = this.toastController.create({
  //     message: data,
  //     duration: 1000
  //  }).then((toastdata) => {
  //   toastdata.present();
  //  });
  //  }
  AddOnlineExamForm(onlineExam): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<OnlineExam>(
      GlobalVariable.SERVICE_API_URL + 'OnlineExamForm/AddOnlineExamForm',
      onlineExam,
      this.httpOptions,
    )
 
  }
  AddExamResult(examResult): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<ExamResult>(
      GlobalVariable.SERVICE_API_URL + 'ExamResult/AddExamResult',
      examResult,
      this.httpOptions,
    )
 
  }
  AddTermsInfo(termsinfo): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<ExamResult>(
      GlobalVariable.SERVICE_API_URL + 'StudentInfo/AddTermsInfo',
      termsinfo,
      this.httpOptions,
    )
 
  }
  GetUserScoreList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "UserScore/GetUserScore",
      this.httpOptions
    );
  }

  GetApplicationFormList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "ApplicationForm/GetAllApplicationForm",
      this.httpOptions
    );
  }


  GetAllTransactionsList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "Student/GetAllTransactionstatus",
      this.httpOptions
    );
  }

  GetUserList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "OnlineExamForm/GetOnlineExamForm",
      this.httpOptions
    );
  }
  GetAllOnlineExamForm() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "OnlineExamForm/GetOnlineExamForm",
      this.httpOptions
    );
  }

  GetAlltermsreprot() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "StudentInfo/GetAllTermsInfo",
      this.httpOptions
    );
  }

  AddOtpDetails(RegisterOTP): Observable<any> {
    return this.http.post<RegisterOTP>( GlobalVariable.SERVICE_API_URL +"OtpDetails/AddOtpDetails", RegisterOTP, this.httpOptions);
  }
  GetOtpDetailsByMobileNumber(Mobile): Observable<any> {
    return this.http.get<any>( GlobalVariable.SERVICE_API_URL +"OtpDetails/GetOtpDetailsByMobileNumber?Mobile="+Mobile,this.httpOptions);
  }
  UpdateOtpStatus(mobile,otp): Observable<any> {
    return this.http.get<RegisterOTP>( GlobalVariable.SERVICE_API_URL +"OtpDetails/UpdateOtpStatus?otp="+otp+"&mobile="+mobile);
  }
  GetAllCityList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "City/GetAllCity",
      this.httpOptions
    );
  }
  ResendOtp(Mobile,Otp): Observable<any> {
    return this.http.get<RegisterOTP>( GlobalVariable.SERVICE_API_URL +"OtpDetails/UpdateOtpByMobileNumber?Mobile="+Mobile+"&Otp="+Otp);
  }
  GetOnlineExamFormById(Id): Observable<any> {
 
    return this.http.get<any>( GlobalVariable.SERVICE_API_URL +"OnlineExamForm/GetOnlineExamFormById?Id=" +Id, this.httpOptions);
  }
  GetOnlineExamFormByEmailId(Email): Observable<any> {
 
    return this.http.get<any>( GlobalVariable.SERVICE_API_URL +"OnlineExamForm/GetOnlineExamFormByEmailId?Email=" +Email, this.httpOptions);
  }
  GetAttemptHistoryById(Id): Observable<any> {

    return this.http.get<any>( GlobalVariable.SERVICE_API_URL +"OnlineExamForm/GetAttemptHistoryById?Id=" +Id, this.httpOptions);
  }
  AddAttemptHistory(AttemptHistory): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<AttemptHistory>(
      GlobalVariable.SERVICE_API_URL + 'OnlineExamForm/AddAttemptHistory',
      AttemptHistory,
      this.httpOptions,
    )
 
  }

  UpdateAttemptHistory(AttemptHistory): Observable<any> {
    return this.http.post<AttemptHistory>( GlobalVariable.SERVICE_API_URL +"OnlineExamForm/UpdateAttemptHistory",AttemptHistory,this.httpOptions);
  }


  GetResultCertiById(Id): Observable<any> {
    return this.http.get<any>( GlobalVariable.SERVICE_API_URL +"ResultCerti/GetResultCertiById?Id=" +Id, this.httpOptions);
  }
  GetExamMarksById(Id): Observable<any> {
    return this.http.get<any>( GlobalVariable.SERVICE_API_URL +"ExamResult/GetExamResultById?Id=" +Id, this.httpOptions);
  }
  AddCourses(Coursesinterested): Observable<any> {
    return this.http.post<Coursesinterested>( GlobalVariable.SERVICE_API_URL +"Coursesinterested/AddCoursesinterested", Coursesinterested, this.httpOptions);
  }
  UploadPdf(SavePdf): Observable<any> {
    return this.http.post<SavePdf>( GlobalVariable.SERVICE_API_URL +"StudentInfo/SaveCertificatePDF", SavePdf, this.httpOptions);
  }
  SendFile(Email, Id, Name): Observable<any> {
    return this.http.post( GlobalVariable.SERVICE_API_URL +"StudentInfo/SendCertificateMail?Email="+ Email+"&Id="+Id+"&Name="+Name, this.httpOptions);
  }
  // SendTermsEmail(): Observable<any> {
  //   return this.http.post( GlobalVariable.SERVICE_API_URL +"StudentInfo/SendTermsMail?Email="+ Email+ "&Name="+Name, this.httpOptions);
  // }

  SendTermsEmail(Termsinfo): Observable<any> {
    return this.http.post<Termsinfo>( GlobalVariable.SERVICE_API_URL +"StudentInfo/SendTermsMail", Termsinfo, this.httpOptions);
  }
  
}
