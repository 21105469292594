import { Component, OnInit } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { Coursesinterested } from '../user1'
import { AttemptHistory } from '../user1'

import { Observable } from 'rxjs'
import { Data, Router } from '@angular/router';
import { MatSelectModule } from '@angular/material/select'
import { FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'

@Component({
  selector: 'app-brochures',
  templateUrl: './brochures.component.html',
  styleUrls: ['./brochures.component.css']
})
export class BrochuresComponent implements OnInit {
  isVisible:boolean;
  isExperience:boolean;
  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router, private formBuilder: FormBuilder) {

   }
   yes() {
    this.isVisible = true;
    this.isExperience = true;
  }
  no() {
    this.isVisible = false;
    this.isExperience = false;
    this.router.navigate(['/courses']);
  
  }
  onNextresult(){
    this.router.navigate(['/courses']);
}
  ngOnInit() {
  }

}
