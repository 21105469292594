<!-- Sidebar Area End Here -->
<div class="dashboard-content-one">

    <div class="container">
        <div class="row row-head">

            <div class="col-md-6 col-xs-6">
                <img src="../../assets/images/logo/LOGO-solutions.png">

            </div>
            <!-- <div class="col-md-3 col-xs-3 " class="info" *ngFor="let i of UserList">
                <h6><b style="color: #1882B4;">Name: </b>{{i.FName}} &nbsp;{{i.LName}} </h6>
                <h6> <b style="color: #1882B4;">Mobile:</b> {{i.Mobile}} </h6>
                <h6><b style="color: #1882B4;">E-mail:</b> {{i.Email}} </h6>


            </div> -->
            <!-- <div class="col-md-6 col-xs-6">
                    <div class="col-md-12 nxt-pre-btn" *ngIf="start" >
                        <h5 class="number">{{ currentIndex + 1 }}/{{ questions.length }}</h5> -->
            <!-- <input *ngIf="(currentIndex+1) != questions.length" type="button" class="btn" value="Next" (click)="next()" /> 
                        <button type="button" *ngIf="currentIndex - 1 != questions.length"
                            class="btn-fill-lg start-btn btn1 buttons" value="Prev" (click)="prev()">
                            Prev
                        </button>
                        <button type="button" *ngIf="currentIndex + 1 != questions.length"
                            class="btn-fill-lg start-btn btn1 buttons" value="Next" (click)="next()">
                            Next
                        </button>
                        <div class="time"> <span id="" class="clock" style="font-size: 19px;  font-weight: 600;">{{clock}}</span></div> 
                    </div> 
                    
                </div>  -->

        </div>
    </div>
    <!-- Breadcubs Area Start Here -->
    <!-- <div class="breadcrumbs-area">
        <h2 style="text-align: center;">Test Your Knowledge On ASME...!</h2>
        <div class="time"> <span id="" class="clock" style="font-size: 19px;  font-weight: 600;">{{clock}}</span></div>

    </div> -->


    <div class=" height-auto div-box">
        <div class="">
            <!-- <div class="well col-md-12"> -->

            <div class="container">
                <div *ngIf="flag" class="col-md-12 start-row instru "
                    style="text-align: center; margin-left: -9px !important;    overflow-y: scroll !important;
                    height: 452px;">
                    <h2 style="text-align: center;">Terms and Conditions</h2>

                    <ul class="para">
                      <li> <strong>1.</strong>&nbsp; All Intellectual Property Rights in the Course Materials, Online Courses and the speeches made by trainers in the training, will remain, the intellectual property of <b> Express Engineering Solutions </b>, whether adapted, written for or customized for the Client or not.</li><br>
                        <!-- <strong>2.</strong>&nbsp; Max number of attempts allowed  are 2.<br> -->
                      <li> <strong>2.</strong>&nbsp;<strong>You are not authorized to:-</strong>  </li> 
<ul style="list-style-type: square; margin-left: 20px;">
                        <li>	copy, modify, reproduce, re-publish, sub-license, sell, upload, broadcast, post, transmit or distribute any of the Course Materials without prior written permission;</li>
                        <li>	Record video or audio tape, relay by videophone or other means the Online Course.</li>
                        <li>	Use the Course Materials in the provision of any other course or training whether given by us or any third party trainer;</li>
                        <li>Remove any copyright or other notice of Express Engineering Solutions on the Course Materials;</li>
                        <li>Modify, adapt, merge, translate, disassemble, decompile, reverse engineer (save to the extent permitted by law) any software forming part of the Online Courses.</li>
                        <li>Breach by you of this clause 2 shall allow us to immediately terminate these terms and conditions with you and cease to provide you with any Services, including but not limited to access to the Online Courses.</li>
</ul>
                       
                        
                  
<li> <strong>3.</strong>&nbsp; <strong>Termination :-  </strong></li>

                We shall be entitled to terminate these terms and conditions and cease to provide you with any Services with immediate effect in the event that you:<br>
                <ul style="list-style-type: square; margin-left: 20px;">

<li>fail to pay when due your Fees; </li>
<li>act in an aggressive, bullying, offensive, threatening or harassing manner towards any trainer.</li>
<li>cheat or plagiaries any work which you are required to prepare or submit in connection with the Services or during any examination taken in connection with the Services;</li>
<li>steal or act in fraudulent or deceitful manner towards us or our employees or any other students, trainers who may be on our premises or attending our online course;</li>	
<li>intentionally or recklessly damage our property or the property of our employees or other students attending our classroom training; </li>
<li>commit any criminal offence committed on our premises or where the victim is our employee or student;</li>
<li>are in breach of these terms and conditions.</li>

                </ul> 
                
                
                
<li> <strong>4.</strong>&nbsp; <strong>Force Majeure</strong><br>
                        Express Engineering Solutions shall not be liable to you for any breach of its obligations or termination under these terms and conditions arising from causes beyond its reasonable control, including, but not limited to, fires, floods, earthquakes, volcanoes and other Acts of God, terrorism, strikes, delay caused by transport disputes, failure to provide a course caused by a death in the trainer’s family, illness of the trainer, Government edict or regulation.</li>
                       
                       
                       
                       
                       <li><strong>5.</strong>&nbsp;<strong>Data Protection </strong></li>
                       <ul style="list-style-type: square; margin-left: 20px;">
                       <li>When you register with us you will need to provide certain Data such as your contact details and demographic information. We will store this Data and use it to contact you, provide you with details of the Services you have purchased and otherwise as required during the normal provision of the course.</li>
                       <li> We may also use the above Data, and similar Data you provide us in response to surveys, to aggregate user profiles and, unless you click on the relevant button on the Registration Form, provide you with communications. We will not pass any personal data onto anyone outside of Express Engineering Solutions.</li>
	<li>Express Engineering Solutions Endeavour to take all reasonable steps to protect your personal Data including the use of encryption technology, but cannot guarantee the security of any Data you disclose. You accept the inherent security implications of being and transacting on-line over the internet and will not hold us responsible for any breach of security.</li>

                       
</ul>
                       
                       <li><strong>6.</strong>&nbsp; Although Express Engineering Solutions aims to provide the Services to the highest standards of the industry, neither it, nor its trainers accept any liability for (i) any inaccuracy or misleading information provided in the programmes or Course Materials and any reliance by Client on any such information, (ii) any loss or corruption of data, (iii) any loss of profit, revenue or goodwill, or (iv) any indirect, special or consequential loss arising from any breach of the terms of this Agreement.</li>
                        
                       <li><strong>7.</strong>&nbsp; <strong>Governing Law and Jurisdiction. </strong></li>
                        This Agreement shall be governed by, and interpreted in accordance with the laws in Pune, India. This Agreement is executed at Pune, India and it is hereby agreed that the Courts situated in Pune, India alone shall have exclusive jurisdiction to the exclusion of all other Courts having concurrent jurisdiction in law. <br>
I have read & understood the above and accept all the terms & conditions. I shall be liable to penalties & legal charges if found guilty of breaching any of the above terms & conditions.

                        
                    </ul>


                    <label style="font-weight:400 !important;"><input type="checkbox" [(ngModel)]="checked" name="checkbox" value="value"
                            id="che" >I have read and agree to the Terms and Conditions.
                    </label>
                </div>
                <div class="col-md-12 start-row" style="text-align: center;">
                    <!-- <input *ngIf="!start" type="button" class="btn" value="{{buttonname}}" (click)="startQuiz()" /> -->
                    <button *ngIf="flag" class="btn-fill-lg start-btn mar" [disabled]="!checked"  (click)="sendEmail()" id="startbutton">
                        <i class="fa"></i>AGREE AND CONTINUE
                    </button>
                </div>
            </div>


            <div class="container" *ngIf="start">
                <div class="col-md-12 que-row instru margin" *ngIf="start">
                    <h5>{{ currentQuestionSet.question }}</h5>
                    <label *ngFor="let o of currentQuestionSet.option">
                        <div class="option-row">
                            <input class="option-in" type="radio" style="color: black;" id="s-option"
                                name="{{ currentQuestionSet.id }}" value="{{ o.optionid }}"
                                [(ngModel)]="currentQuestionSet.selected" />{{ o.name }}
                        </div>
                    </label>
                    <!-- <div *ngFor="let o of currentQuestionSet.option">
                        <div class="option-row" >
                            <input class="option-in" type="radio" style="color: black;" id="s-option" 
                                name="{{ currentQuestionSet.id }}" value="{{ o.optionid }}"
                                [(ngModel)]="currentQuestionSet.selected" />{{ o.name }}
                        </div>                     
                    </div> -->


                </div>
                <div class="col-md-12 count" *ngIf="start">
                    <h5 class="number">{{ currentIndex + 1 }}/{{ questions.length }}</h5>
                    <!-- <input *ngIf="(currentIndex+1) != questions.length" type="button" class="btn" value="Next" (click)="next()" /> -->
                    <button type="button" *ngIf="currentIndex - 1 != questions.length"
                        class="btn-fill-lg start-btn btn1 buttons " value="Prev" (click)="prev()">
                        Prev
                    </button>
                    <button type="button" *ngIf="currentIndex + 1 != questions.length"
                        class="btn-fill-lg start-btn btn1 buttons" value="Next" (click)="next()">
                        Next
                    </button>
                </div>
                <!-- <div class="col-md-12" *ngIf="start">
                    <h5>{{currentIndex-1}}/{{questions.length}}</h5>
                    <input *ngIf="(currentIndex-1) != questions.length" type="button" class="btn" value="Prev" (click)="prev()" />
                </div> -->



                <br />
                <!-- <div class="col-md-12 start-row" style="text-align: center;">
                   
                    <button *ngIf="flag" class="btn-fill-lg start-btn" (click)="startQuiz()">
                        <i class="fa"></i>Start Exam
                    </button>
                </div> -->


                <!-- <button class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [disabled]="isLoading" (click)="onSubmit()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i> {{button}}</button>
                <button type="reset" class="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button> -->

                <div class="col-md-12" *ngIf="start" style="text-align:center;">
                    <!-- <input type="button" class="btn" value="Submit" (click)="submit()" /> -->
                    <button *ngIf="currentIndex + 1 == questions.length" class="btn-fill-lg start-btn btnsubmit"
                        (click)="submit()">
                        <i class="fa"></i>Submit
                    </button>
                </div>

            </div>
            <div *ngIf="gameover">
                <div *ngIf="gameover" class="res">
                    <h3>Result: {{ answers }}/{{ questions.length }}</h3>
                    <div *ngFor="let i of UserList">
                        <!-- <h2 class="thxtext">Thank you for participating in online Exam!</h2> -->
                        <h2 class="passed" *ngIf="answers > -1 && isOne"> Thank You <span class="blue">{{i.FName}}
                                &nbsp;{{i.LName}}</span> for participating in this test <br>
                            You tried well.<br>
                            Your score is <span class="red">lower than average.</span><br> but don't worry! You can
                            definitely improve your knowledge about <br><b> ASME Sec. VIII Div-1 </b>by taking our <span
                                class="blue"><br>Pressure Vessel Design Advance Course.</span> </h2>

                        <h2 class="passed" *ngIf="answers > -1 && isTwo"> Thank You <span class="blue">{{i.FName}}
                                &nbsp;{{i.LName}}</span> <br>You tried well.<br>
                            Your score is <span class="red"> Average. </span><br> But don't worry! You can definitely
                            improve your knowledge about <br> <b> ASME Sec. VIII Div-1 </b>by taking our <span
                                class="blue"><br>Pressure Vessel Design Advance Course.</span></h2>

                        <h2 class="passed" *ngIf="answers > -1 && isThree"> Thank You <span class="blue">{{i.FName}}
                                &nbsp;{{i.LName}}</span> You tried well.<br>
                            your score is <span class="blue">Good.</span> <br> But you shoulld still upgrade your
                            knowledge. We recommend you to join our <span class="blue"> <br>Pressure Vessel Design
                                Advance Course.</span> </h2>

                      

                    </div>
                    
                </div>
                <br>
                <div class="col-md-3"></div>
                <div class="col-md-6" style="text-align: center;">
                    <button type="button" class="btn-fill-lg start-btn btnsubmit nxtres"
                        (click)="onNextresult()">Next</button>
                        <br>
                        <br>
                        <div class="blink">
                        <h2 class="steps green blink-text">You are just 3 steps away from getting certificate </h2>
                    </div>
                    </div>

                <div class="col-md-3"></div>

                <!-- <div class="col-md-3"></div>
                <div class="col-md-6" style="text-align: center;">
                  
                </div>

                <div class="col-md-3"></div> -->

        </div>


        </div>
    </div>
    <!-- <div class="container" *ngIf="gameover">
        <div class="row">
       
    </div>
</div> -->
</div>