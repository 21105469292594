<div class="card ">
    <div class="card-body">
        <form class="new-added-form" #userForm="ngForm" name="form" (ngSubmit)="userForm.form.valid" novalidate>
<div class="col-md-4"></div>
<div class="col-md-4">
<div class="row">
    <div class="col-xl-12 col-lg-6 col-12 form-group">
        <label style="font-size: 20px;">Verify OTP </label>
        <label style="font-size: 16px;"> OTP  Sent on Your Registered E-Mail ID.</label>
      
        <!-- <input type="number" placeholder="" class="form-control" name="Aadhar" [(ngModel)]=onlineExam.Aadhar #Aadhar="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Aadhar.invalid }" required minlength="12" (onInputChange)="onOtpChange($event)"> -->
        <ng-otp-input   (onInputChange)="onOtpChange($event)" name="Aadhar"  [config]="{length:6}"></ng-otp-input>
        <!-- <div class="invalid-feedback">
            <div  class="alert alert-danger"> Aadhar Number is required</div>
            <div class="alert alert-danger"> Aadhar Number must be valid</div>
        </div> -->
    </div>
</div>

<br>
<div class="col-md-4"></div>
<div class="col-md-4">

<!-- <div class="time"> <span id="" class="clock" style="font-size: 15px; font-weight: 200; margin-left: 32px;">{{clock}}</span></div> -->
 </div>
<div class="col-md-4"></div>
<br><br>
<div class="row">
    <div class="col-xl-4 col-lg-4 col-6 form-group " >
        <button type="button" class="btn-fill-lg start-btn btnsubmit verify" (click)="Verify()">Verify</button>
    </div>
    <div class="col-xl-8 col-lg-8 col-6 form-group resend">
        <button type="button" class="btn-fill-lg start-btn btnsubmit" (click)="ResendOtp()" >Resend</button>
        <!-- <h6 class="code">Don't receive  OTP ? <a class="resendd" (click)="ResendOtp()" >Resend OTP</a> </h6> -->
    </div>
</div>

</div>
    <div class="col-md-4"></div>
</form>
</div>
</div>