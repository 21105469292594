export const GlobalVariable = Object({
  //  SERVICE_API_URL: "http://localhost:58429/api/",
  // SERVICE_API_URL: "http://192.168.1.42:3000/api/",
  //SERVICE_API_URL: "https://legalapi.sivtpune.com/api/",
  SERVICE_API_URL: "https://legalapi.express-engg.com/api/",
   //SERVICE_API_URL: "https://express-engg.com/api/",
  UserName: "orgasatva@gmail.com",
  Mobile: "",
  Address: "",
  CustomerId: 0,
  UserExamId: 0,
  FirstName:"",
  Lastname:" ",
  Adhaar:"",
  MobileNumber:"",
  Email:"",
  OTP:""

});
