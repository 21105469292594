import { Component, OnInit } from "@angular/core";
import { ExamResult, OnlineExam, ResultCerti, AttemptHistory, SavePdf } from "../user1";
import { CommonserviceService } from "../commonservice.service";
import { Routes, RouterModule } from "@angular/router";
import { ActivatedRoute, Router } from "@angular/router";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas'; 
import {formatDate } from '@angular/common';
import { NotificationService } from "../notification.service";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import * as svg from 'save-svg-as-png';
import { GlobalVariable } from 'src/app/Globals'
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
/* ES5 */

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {
  
  UniqID: any;
  per: any;
  Id: any;
  today= new Date();
  jstoday = '';
  name = "Angular";
  domains = [];
  isVisible: boolean = false;
  isEditable: boolean;
  resultCerti: ResultCerti;
marks: any;
savePdf: SavePdf;
UserId: any
  constructor(
    private service: CommonserviceService,
    private router: Router,
    private route: ActivatedRoute,
    protected _notificationSvc: NotificationService
  ) {
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    // alert(this.jstoday);
     this.resultCerti = new ResultCerti();
     this.savePdf = new SavePdf();
     this.resultCerti.ExamResult = new ExamResult();
     this.resultCerti.OnlineExamForm = new OnlineExam();
     this.resultCerti.AttemptHistory = new AttemptHistory();

    this.route.params.subscribe((params) => {
      this.Id = params.id;
      this.service.GetResultCertiById(this.Id).subscribe((result) => {
        this.resultCerti = result;
        console.log(this.resultCerti.ExamResult);
        this.marks = parseInt(this.resultCerti.ExamResult.correct)*5;
   this.per = parseInt(this.resultCerti.ExamResult.correct)/50;
   this.per = this.per*100;
        console.log(this.resultCerti);
      });

    //   this.service. GetAttemptHistoryById(this.UniqID)
    //   .subscribe((result) => {
    //   this.attemptHistory = result;
    //   console.log(this.attemptHistory);
    // });


    });
  }
  simpleAlert(){
    Swal.fire('Certificate has been sent to your registered Email Id' , 'success');
  }
  alertWithSuccess(){
    Swal.fire('', 'Certificate has been sent to your registered Email Id', 'success')
  }
  printPage() {
    window.print();
  }
  ngOnInit() {
    this.UniqID = localStorage.getItem('UniqCode');
    this.UserId = localStorage.getItem('dataSource');

    // this.service.GetMigrationList().subscribe((result) => {
    //   this.migration = result.map((item) => {
    //     return item;
    //   });
    // });
  }




  SaveCertificate(){
 
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      var imgWidth = 200;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;   
      const contentDataURL = canvas.toDataURL('image/png')  
       let pdf = new jspdf.jsPDF('l', 'mm', 'a4', true); 
      var position = 0;  
       pdf.addImage(contentDataURL, 'PNG', 25, position, 250, imgHeight)  
      var imgURL = contentDataURL.split(","); 
      this.savePdf.Img = imgURL[1];
      this.savePdf.Id = this.UserId;
      // this.savePdf.Id = "3";
      // console.log(imgURL[1]);
      // imgURL[1] = "abcd";
     console.log(this.savePdf)
  
      this.service.UploadPdf(this.savePdf).subscribe((resp) => {
        if (resp == "Failed") {
          // alert("Something went wrong! Please try again.");
        } else {
          this.service.SendFile(GlobalVariable.Email,this.UserId, GlobalVariable.FirstName).subscribe((resp) => {
            // this.alertWithSuccess();
            if (resp == "Failed") {
              // alert("Something went wrong! Please try again.");
            } else {
              
            }
          });
        }
      });
    });  
  }


  pdfpage(){
    this.alertWithSuccess();
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      var imgWidth = 200;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;   
      const contentDataURL = canvas.toDataURL('image/png')  
       let pdf = new jspdf.jsPDF('l', 'mm', 'a4', true); 
      var position = 0;  
       //pdf.addImage(contentDataURL, 'PNG', 0, position, 270, imgHeight)  
       pdf.addImage(contentDataURL, 'PNG', 25, position, 250, imgHeight)  
      //pdf.save('Certificate.pdf');
       // Generated PDF   
      this.SaveCertificate();
       });  
  }
}





