import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { User } from '../user';




@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  model: any = {};
  service: any;
  userModel = new User('','','','',0,'', '', '', '',   '' , '' ,  '', '', '', '', '', '', '', '', 0 , 0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', false);
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  religions = ['Hindu', 'Muslim', 'Sikh', 'Bengali', 'Hindu' ];
  casts = ['Bramhin', 'Patil', 'Rajput', 'Marwadi', 'Bramhin' ];
  subcasts = ['Bramhin', 'Patil', 'Rajput', 'Marwadi', 'Daivadnya' ];
  days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  years = ['1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1191', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2001', '2002'];
  moonsigns = ['Aries', 'Aries', 'Gemini ', 'Cancer ', 'Leo ' , 'Virgo ', 'Libra  ', 'Scorpio  ', 'Sagittarius ', 'Capricorn ', 'Aquarius ', 'Pisces ' ];
  bloods = ['A Positive', 'A Negative', 'B Positive', 'B Negative', 'O Positive', 'O Negative', 'AB Positive', 'AB Negative' ];

  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidFormSubmitted = false;

  numberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidNoSubmitted = false;



  onSubmit() {
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.userModel));
  }

  constructor() { }

  ngOnInit() {

  }

}
