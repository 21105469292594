<div class="conatainer-fluid">
    <div class="row" style="justify-content: center;">
        <button (click)="pdfpage()" id="btnprint">Download Marksheet</button>
    </div>
    <div class="row certi-row" >
        <!-- <div>
        <button (click)="pdfpage()" id="btnprint">print</button> 
    </div> -->
    <div class="padding-div" id="contentToConvert">
<section class="section-certificate" >
    <br>
    <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12 text-center">
        <img src="../../assets/images/logo/LOGO-solutions.png" class="logo-img" />
    </div>
    <br>
    <br>
    <!-- <div class="row header-row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6 header-left">
            <h6>Government Recognized</h6>
        </div>
        <div class="col-lg-6 col-md-5 col-sm-6 col-xs-12 text-center">
            <img src="/assets/img//erp/logo.png" class="logo-img" />
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3 col-xs-6 header-right">
            <h6>Reg. No. MH/605/2020/Pune</h6>
            <h6>MSME - MH26DO130978</h6>
            <h6>ISO 9001 : 2015</h6>
            <img src="/assets/images/cyber.png" class="logo-right-img" />
        </div>
    </div> -->

    <div class="row headerName-row" style="margin: 0;">
        <!-- <h3 class="header" style="color: red;"><b>कौशल्य विकास मुक्त विद्यालयी आणि व्यवसाय शिक्षण परिक्षा मंडळ, पुणे महाराष्ट्र</b></h3>
        <h5 class="header-h5"><b>Kaushalya Vikas Board of Open Schooling & Vocational Education Examination Pune Maharashtra.</b></h5>
        <p class="header-p1"><b>Established Under Societies Registration Act 1860 & BPT Act 1950 as per Govt. of Maharashtra.</b></p>
        <p class="header-p2">Regd. With Ministry of Micro, Small & Medium Enterprises (MSME) Govt. of India.</p> -->
        <p class="header-p3 heading-c" ><b>STATIC EQUIPMENT DESIGN AND PvELite DESIGN Test Result </b></p>
    </div>
    <br>
    <br>
    <div class="row" style="margin: 0;">
        <div class="col-lg-12 col-md-12 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>Candidate Name :- &nbsp;{{resultCerti.OnlineExamForm.FName}} {{resultCerti.OnlineExamForm.MName}} {{resultCerti.OnlineExamForm.LName}}</b></p>
        </div>
    </div>
    <br>
    <div class="row" style="margin: 0;">
        <div class="col-lg-6 col-md-6 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>Email ID :- &nbsp; {{resultCerti.OnlineExamForm.Email}}</b></p>

        </div>
        <br>
        <div class="col-lg-6 col-md-6 col-xs-12 text-right">
            <p class="header-p3 header-h2 "><b>Mobile No. :-  &nbsp;{{resultCerti.OnlineExamForm.Mobile}}</b></p>

        </div>
    </div>
    <br>
    <div class="row" style="margin: 0;">
        <div class="col-lg-12 col-md-12 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>Company/Organization/College Name :-  &nbsp;{{resultCerti.OnlineExamForm.companyname}}</b></p>
        </div>
    </div>
    <br>
    <!-- <div class="row" style="margin: 0;">
        <div class="col-lg-12 col-md-12 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>No. Of Attempts :-  &nbsp;{{this.resultCerti.AttemptHistory.Count}}</b></p>
        </div>
    </div>
    <br> -->
    <div class="row" style="margin: 0;">
        <div class="col-lg-12 col-md-12 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>Result :-  &nbsp;{{resultCerti.ExamResult.correct}}/50</b></p>
        </div>
    </div>
    <br>
    <div class="row" style="margin: 0;">
        <div class="col-lg-12 col-md-12 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>Percentage :- &nbsp;{{per}}%</b></p>
        </div>
    </div>
    <br>
    <div class="row" style="margin: 0;">
        <div class="col-lg-12 col-md-12 col-xs-12 text-left">
            <p class="header-p3 header-h2 "><b>Date :- &nbsp;{{resultCerti.ExamResult.Date}}</b></p>
        </div>
    </div>

  

 





    <br>



</section>
</div>

        
</div>
</div>