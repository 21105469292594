import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { ExamResult } from '../user1'
import { DeviceDetectorService } from "ngx-device-detector";
import { AttemptHistory } from '../user1'
import { Termsinfo } from '../user1'
import { IpServiceService } from '../ip-service.service';  
import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { Data, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
//import { startTimer } from '../../assets/js/timerCount';
// declare const startTimer: any;
import {timer } from 'rxjs';
declare let $: any;
const counter = timer(0, 1000);
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
})
export class QuizComponent implements OnInit {
  status = 'start'
  @ViewChild('countdown') counter: CountdownComponent
  examResult: ExamResult
  attemptHistory: AttemptHistory
  termsinfo:Termsinfo
  list: any[];
  data: any[];
  TimeCheck: any[]
  minute: any;

  ipAddress:string;
  message:string;
  isValid:boolean;
  // isPassed: boolean;
    isFived: boolean;
    isFifteen:boolean;
    isOne:boolean=false;
    isTwo:boolean=false;
    isThree:boolean=false;
    checked: boolean = false;
  attemptcount: any;
  timer:'00:30';
  public clock;
  finishTest() {
    console.log('count down', this.counter)
    this.status = 'finished'
    this.counter.restart()
  }

  resetTimer() {
    this.counter.restart()
  }
  flag: boolean
  questions: any
  currentIndex: number
  notAttempted: any
  isCount: boolean;
  LastCount: boolean;
  answers: any
  currentQuestionSet: any
  start = false
  gameover = false
  buttonname = 'Start Exam'
UserId: any;
UserList: any[];
UniqID : any; 
attemptCount : any;
deviceInfo = null;
  isDesktopDevice: boolean;
  isTablet: boolean;
  isMobile: boolean;

  constructor(private service: CommonserviceService, private http: HttpClient, private deviceService: DeviceDetectorService,private router: Router,private ip:IpServiceService) {
    styles: ['h1 { font-color:red; }']
    this.examResult = new ExamResult() ;
    this.attemptHistory = new  AttemptHistory();
    this.termsinfo= new Termsinfo();
    this.UserId = GlobalVariable.UserExamId;
    this.UniqID = localStorage.getItem('UniqCode');
    // alert(this.UserId);
    console.log(this.UserId);
    this.UserList = [];
    //Creating questions Json data
    this.flag = true;
    this.clock = "25:00";
    
    this.isValid = false;
    this.questions = [
      {
        id: 1,
        question:
          " In flat head design as per 'UG-34', efficiency used in equation is weld joint efficiency between flat head to shell Junction?",
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
          // { optionid: 3, name: '33.3' },
          // { optionid: 4, name: '76' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 2,
        question: 'Can we have a U stamp only for 1/2" pipe ?',
        option: [
          {
            optionid: 1,
            name: 'YES',
          },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 3,
        question: 'Large opening on flat head should be designed as per Mandatory Appendix 1-7?',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
          // { optionid: 3, name: '5' },
          // { optionid: 4, name: '6' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 4,
        question:
          'Local stresses on nozzle attached with conical shell can be calculated as per WRC bulletin 107 / 297? ',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
        
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 5,
        question: 'We can’t design vessel with pressure more than 3000 psi with ASME Sec VIII Div 1 ?',
        option: [
          {
            optionid: 1,
            name:
              'YES',
          },
          {
            optionid: 2,
            name:
              'NO',
          },
          { optionid: 3, name: "I Don't know the answer." },
         
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 6,
        question:
          'In slip on flange design we calculate radial stresses in Flange?',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
       
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 7,
        question: 'As per Mandatory appendix 2 can we design flange with Full face gasket?',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
       
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 8,
        question: 'Minimum thickness for shell or head as per UG -16 for unfired steam boiler is….. ',
        option: [
          { optionid: 1, name: '1.5 mm' },
          { optionid: 2, name: '2.5 mm' },
          { optionid: 3, name: '3.5 mm' },
          { optionid: 4, name: '6 mm' },
          { optionid: 5, name: "I Don't know the answer." },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 9,
        question:
          'Efficiency value used in cylinder design as per equation UG-27 is always a weld joint efficiency defined as per Table UW-12 ?',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },        
          // { optionid: 4, name: '+4, +2' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 10,
        question:
          " For lethal service application as per 'UW-2', butt welds in vessel needs to be post weld heat treated for MOC as High alloy steel ?",
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },

        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 11,
        question:
          'As per Full radiography requirement of RT-1 every butt weld joint needs to be checked for radiography.',
        option: [
          { optionid: 1, name: 'TRUE' },
          { optionid: 2, name: 'FALSE' },
          { optionid: 3, name: "I Don't know the answer." },
          // { optionid: 3, name: '13' },
          // { optionid: 4, name: '19' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 12,
        question:
          'On name plate it is necessary to mark type of construction for welded vessel as ‘W’ and for brazed vessel as ‘B’ as per 2019 code edition and clause UG-116.',
        option: [
          { optionid: 1, name: 'TRUE' },
          { optionid: 2, name: 'FALSE' },
          { optionid: 3, name: "I Don't know the answer." },
          // { optionid: 3, name: '13' },
          // { optionid: 4, name: '19' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 13,
        question:
          'For no Radiography of vessel, name plate needs to be marked with RT-4.',
        option: [
          {
            optionid: 1,
            name:
              'TRUE',
          },
          {
            optionid: 2,
            name:
              'FALSE',
          },
          { optionid: 3, name: "I Don't know the answer." },

        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 14,
        question:
          ' Circumferential weld joint between spherical dished end and cylindrical shell comes under which Category from the following categories  as per UW-3 ?',
        option: [
          {
            optionid: 1,
            name:
              'Category D',
          },
          {
            optionid: 2,
            name: 'Category C',
          },
          {
            optionid: 3,
            name:
              'Category B',
          },
          { optionid: 4, name: 'Category A' },
          { optionid: 5, name: "I Don't know the answer." },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 15,
        question:
          'Can category ‘D’ weld joint, which is nozzle to shell weld be of Type 1 butt joint as per Table UW-12?',
        option: [
          {
            optionid: 1,
            name:
              'YES',
          },
          {
            optionid: 2,
            name: 'NO',
          },
          { optionid: 3, name: "I Don't know the answer." },
         
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 16,
        question:
          'Factor of safety for allowable stress based on UTS for materials used in design of pressure vessels as per ASME Section VIII Div.1 is......',
        option: [
          { optionid: 1, name: '1.5' },
          { optionid: 2, name: '2.4' },
          { optionid: 3, name: '3' },
          { optionid: 4, name: '3.5' },
          { optionid: 5, name: "I Don't know the answer." },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 17,
        question:
          'For jacketed vessel if support lug is on jacket then jacket to end closure junction should be designed as per…',
        option: [
          { optionid: 1, name: 'UG-27' },
          { optionid: 2, name: 'Appendix - 9' },
          { optionid: 3, name: 'U2(g)' },
          { optionid: 4, name: 'U2(g) & Appendix-9' },
          { optionid: 5, name: "I Don't know the answer." },

        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 18,
        question:
          'For external pressure design, if stiffener is required, will it be acceptable if the stiffener is not welded with shell?',
        option: [
          { optionid: 1, name: 'YES ' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
         
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 19,
        question: 'As per UG-99 for vertical vessel, if we are conducting hydro test in horizontal position in shop, is it required to add static head difference between horizontal position and vertical position as extra head in hydro test pressure calculated?',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
          
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 20,
        question:
          'Is U stamp compulsory for a vessel with 1000 mm dia, carrying water at 20 bar pressure and 90 Deg Cel temperature ?',
        option: [
          { optionid: 1, name: 'YES' },
          { optionid: 2, name: 'NO' },
          { optionid: 3, name: "I Don't know the answer." },
         
        ],
        answer: 2,
        selected: 0,
      },
      // {
      //   id: 21,
      //   question: 'Reinforcement calculations for small nozzles in cylindrical and conical pressure vessel are excluded by which clause ? ',
      //   option: [
      //     { optionid: 1, name: 'UG-42(c)(3)' },
      //     { optionid: 2, name: 'UG-36(c)(3)' },
      //     { optionid: 3, name: 'UG-39(c)(3)' },
      //     { optionid: 4, name: 'UG-42(c)(3)' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 22,
      //   question:
      //     'For cylinder or cones If Inside diameter < 60” ( 1500 mm) then Large Nozzle opening size = min( 1/3 I.D , 40”(1020 mm))',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'real,  inverted and magnified' },
      //     // { optionid: 4, name: 'virtual, erected and diminished' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 23,
      //   question:
      //     'Large openings in cylindrical and conical shells will be reinforced as per…',
      //   option: [
      //     { optionid: 1, name: '1-7' },
      //     { optionid: 2, name: '1-8' },
      //     { optionid: 3, name: '1-9' },
      //     { optionid: 4, name: '1-5' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 24,
      //   question:
      //     'In loose ring flange design Flange ID should be equal to shell ID?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'only Reason B is correct' },
      //     // {
      //     //   optionid: 4,
      //     //   name: 'neither Assertion A nor Reason B are correct',
      //     // },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 25,
      //   question:
      //     '. Nozzle Local load analysis procedure is given in ASME Sec VIII Div.1?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'a != 0, v != 0' },
      //     // { optionid: 4, name: 'a != 0, v != 0' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 26,
      //   question: 'If a fillet weld is used in conjunction with a groove weld, the nominal thickness is…..',
      //   option: [
      //     { optionid: 1, name: 'Addition the depth of the groove and the throat dimension' },
      //     { optionid: 2, name: 'The depth of the groove or the throat dimension, whichever is greater' },
      //     // { optionid: 3, name: 'concentration gradient' },
      //     // { optionid: 4, name: 'all of the above' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 27,
      //   question:
      //     'The holding time at temperature as specified in Tables UCS-56-1 through UCS-56-11 may be an accumulation of time of multiple postweld heat treatment cycles.',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'it is plasmolysed' },
      //     // { optionid: 4, name: 'nothing happens' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 28,
      //   question: 'As per Table UCS-56-1 PWHT is compulsory for p no 1 material if nominal thickness is above ...',
      //   option: [
      //     { optionid: 1, name: '38 mm' },
      //     { optionid: 2, name: '37 mm' },
      //     { optionid: 3, name: '40 mm' },
      //     { optionid: 4, name: '42 mm' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 29,
      //   question:
      //     'Maximum cooling rate as per UCS-56 allowed is not more than…',
      //   option: [
      //     {
      //       optionid: 1,
      //       name:
      //         '200 Deg C/h',
      //     },
      //     {
      //       optionid: 2,
      //       name:
      //         '222 Deg C/h',
      //     },
      //     {
      //       optionid: 3,
      //       name:
      //         '250 Deg C/h',
      //     },
      //     {
      //       optionid: 4,
      //       name:
      //         '280 Deg C/h',
      //     },
      //   ],
      //   answer: 4,
      //   selected: 0,
      // },
      // {
      //   id: 30,
      //   question:
      //     'Postweld heat treatment is neither required nor prohibited for joints between austenitic stainless steels of the P‐No. 8 group',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'Phosphofructokinase' },
      //     // { optionid: 4, name: 'Pyruvatedikinase' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 31,
      //   question: 'PFHT requirements for carbon steel are provided in….',
      //   option: [
      //     { optionid: 1, name: 'UCS-56' },
      //     {
      //       optionid: 2,
      //       name: 'UCS-66',
      //     },
      //     {
      //       optionid: 3,
      //       name: 'UCS-67',
      //     },
      //     { optionid: 4, name: 'UCS-79' },
      //   ],
      //   answer: 4,
      //   selected: 0,
      // },
      // {
      //   id: 32,
      //   question: 'PFHT is required as per UCS-56 if extreme fiber elongation exceeds …',
      //   option: [
      //     { optionid: 1, name: '2 %' },
      //     { optionid: 2, name: '5 %' },
      //     { optionid: 3, name: '10 %' },
      //     { optionid: 4, name: '15 % ' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 33,
      //   question: 'For P‐No. 1, Gr. No. 1 or 2 impact test can be avoided if it satisfies the requirements of…',
      //   option: [
      //     { optionid: 1, name: 'UG-16(f) ' },
      //     { optionid: 2, name: 'UG-20(f) ' },
      //     { optionid: 3, name: 'UG-21(f) ' },
      //     { optionid: 4, name: 'UG-42(f)  ' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 34,
      //   question: 'Design Temp = 150 Deg Cel ,Nominal Thk = 12 mm ,Shell MOC = SA 516 Gr.70, Type of joint = Type 1,Radiography = Full ,PWHT Required = No Required MDMT = -32 deg Cel ,Does Equipment will be Hydro tested after construction = Yes Is thermal, Cyclic loading applicable = No Does impact testing is exempted as per UG-20(f) ? ',
      //   option: [
      //     { optionid: 1, name: 'YES' },
      //     { optionid: 2, name: 'NO' },
      //     // { optionid: 3, name: 'Ligase' },
      //     // { optionid: 4, name: 'DNA polymerase.' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 35,
      //   question: 'Which pressure to be considered for designing of pressure vessel for internal pressure ?',
      //   option: [
      //     { optionid: 1, name: 'Absolute' },
      //     { optionid: 2, name: 'Gauge' },
      //     { optionid: 3, name: 'Atmospheric' },
      //     // { optionid: 4, name: 'N2 base and phosphate ' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 36,
      //   question: 'MAWP of any component or vessel is its maximum ability to sustain pressure at the bottom most point ? ',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'Proteases' },
      //     // { optionid: 4, name: 'Ligases' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 37,
      //   question:
      //     'LSR in hydro test pressure calculation stands for…',
      //   option: [
      //     { optionid: 1, name: 'Lowest Shear Ratio' },
      //     { optionid: 2, name: 'Lowest Stress Ratio' },
      //     { optionid: 3, name: 'Lowest Strength Ratio' },
      //     // { optionid: 4, name: '21 – 21' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 38,
      //   question: '. As per which clause we can have MAWP = Design pressure while calculating Hydrotest pressure… ?',
      //   option: [
      //     { optionid: 1, name: 'UG-99(b)(36)' },
      //     { optionid: 2, name: 'UG-99(b)(35)' },
      //     { optionid: 3, name: 'UG-99(c)' },
      //     { optionid: 4, name: 'UG-99(b) ' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 39,
      //   question: 'As per IS 875 (Part 3) – 2015 which factor is taking care of local topography ?',
      //   option: [
      //     { optionid: 1, name: 'K1' },
      //     { optionid: 2, name: 'K2' },
      //     { optionid: 3, name: 'K3' },
      //     { optionid: 4, name: 'K4' },
      //   ],
      //   answer: 3,
      //   selected: 0,
      // },
      // {
      //   id: 40,
      //   question:
      //     'As per IS 875 (Part 3) – 2015 factor Kd is given as wind directionality factor ?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'It does not bear roots' },
      //     // { optionid: 4, name: 'It possesses chlorophyll ' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 41,
      //   question:
      //     'DBE earthquake considerations are more conservative than MCE type?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: '‘Y’ shaped arrangement of xylem strands' },
      //     // { optionid: 4, name: 'Vascular bundle covered by bundle sheath' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 42,
      //   question: 'Wind or seismic moment will generate both tensile and compressive stresses in skirt Mounted vessel?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // {
      //     //   optionid: 3,
      //     //   name:
      //     //     'Variation in water requirement of the tree in spring and Autum',
      //     // },
      //     // {
      //     //   optionid: 4,
      //     //   name:
      //     //     'Gradual non-functioning of the wood progressively due to production of functional wood from the cambium outwards',
      //     // },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 43,
      //   question: 'External pressure will create compressive stresses in vessel?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'Osmophores' },
      //     // { optionid: 4, name: 'Pneumathode ' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 44,
      //   question: 'Because of which component steel becomes stainless ?',
      //   option: [
      //     {
      //       optionid: 1,
      //       name: 'Chromium',
      //     },
      //     {
      //       optionid: 2,
      //       name: 'Carbon',
      //     },
      //     { optionid: 3, name: 'Ferrous' },
      //     { optionid: 4, name: 'Tantalum' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
      // {
      //   id: 45,
      //   question: 'Which of the following component is additionally present in SS 316 other than SS 304',
      //   option: [
      //     {
      //       optionid: 1,
      //       name: 'Carbon',
      //     },
      //     { optionid: 2, name: 'Molybdenum' },
      //     {
      //       optionid: 3,
      //       name: 'Nickel',
      //     },
      //     {
      //       optionid: 4,
      //       name: 'Manganese',
      //     },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 46,
      //   question:
      //     'Percentage of carbon in SS304 L is ? ',
      //   option: [
      //     { optionid: 1, name: '0.3 ' },
      //     { optionid: 2, name: '0.03' },
      //     { optionid: 3, name: '0.08' },
      //     { optionid: 4, name: '0.04' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 47,
      //   question: 'Which of the following grade is called LTCS (Low Temp Carbon Steel)?',
      //   option: [
      //     {
      //       optionid: 1,
      //       name: 'SA 106 Gr.B',
      //     },
      //     {
      //       optionid: 2,
      //       name: 'SA 333 Gr.6',
      //     },
      //     { optionid: 3, name: 'SA 312 TP 304' },
      //     { optionid: 4, name: 'SB-444' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 48,
      //   question: 'Tubesheet to shell butt weld details are given in which Figure ?',
      //   option: [
      //     { optionid: 1, name: 'Fig. UW-13.1' },
      //     { optionid: 2, name: 'Fig. UW-13.2' },
      //     {
      //       optionid: 3,
      //       name: 'Fig. UW-13.3',
      //     },
      //     {
      //       optionid: 4,
      //       name: 'Fig. UW-13.4',
      //     },
      //   ],
      //   answer: 3,
      //   selected: 0,
      // },
      // {
      //   id: 49,
      //   question:
      //     'Which is the best way of providing supports in vertical vessels as to distribute load over the total circumference ',
      //   option: [
      //     { optionid: 1, name: 'Leg' },
      //     { optionid: 2, name: 'Lug' },
      //     { optionid: 3, name: 'Saddle' },
      //     { optionid: 4, name: 'Skirt ' },
      //   ],
      //   answer: 4,
      //   selected: 0,
      // },
      // {
      //   id: 50,
      //   question: 'Minimum wear plate width should be min ( b + 1.56 Rm.t , 2 x a ) ?',
      //   option: [
      //     { optionid: 1, name: 'TRUE' },
      //     { optionid: 2, name: 'FALSE' },
      //     // { optionid: 3, name: 'Orchids – Parasitism' },
      //     // { optionid: 4, name: 'Cuscuta - Epiphytism ' },
      //   ],
      //   answer: 2,
      //   selected: 0,
      // },
      // {
      //   id: 6,
      //   question: 'Select the smallest memory size',
      //   option: [
      //     { optionid: 1, name: 'kilobyte' },
      //     { optionid: 2, name: 'megabyte' },
      //     { optionid: 3, name: 'gigabyte' },
      //     { optionid: 4, name: 'terabyte' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
    ]

    this.currentIndex = 0
    this.currentQuestionSet = this.questions[this.currentIndex]
    this.list = [];
    this.examResult.UserId =  GlobalVariable.UserExamId;
    this.data = [];
    this.epicFunction();
 

    $(function() {
      $('#checkb').checked(function() {
          if ($(this).is(':checked')) {
           
              $('#startbutton').removeAttr('disabled');
          } else {
            $('#startbutton').attr('disabled', 'disabled');
          }
      });
  });

  }
  alertWithSuccess(){
    Swal.fire('Thank you...', 'You Will Receive an Acknowledment for the Same Shortly on Your Registered Email Id !', 'success')
   
  }
  alertWithError(){
    Swal.fire('Oops...', 'Something Went Wrong! Please try again.', 'error')
  }
  alertWithMsg(){
    Swal.fire('Your Attempts Finished')
  }
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  simpleAlert(){
    Swal.fire('You have already register with This Email ID.');
  }
  next() {
    this.currentIndex = this.currentIndex + 1
    this.currentQuestionSet = this.questions[this.currentIndex]
    // alert(this.currentIndex)
  }
  prev() {
    this.currentIndex = this.currentIndex - 1
    this.currentQuestionSet = this.questions[this.currentIndex]
  }

  check(){}

  submit() {  
 
    if (this.currentIndex + 1) {
       //alert("Test")
      this.gameover = true
      this.start = false
      this.answers = 0
      this.notAttempted = 0
      this.questions.map((x) => {
        if (x.selected != 0) {
          if (x.selected == x.answer) this.answers = this.answers + 1
        } else {
          this.notAttempted = this.notAttempted + 1
        }
        // console.log(this.notAttempted);
        x.selected = 0
      })
    }
   
this.examResult.UserId =  this.UserId;
this.examResult.FirstName = GlobalVariable.FirstName;

this.examResult.correct = this.answers;
this.examResult.Adhaar = this.UniqID;
this.examResult.MobileNumber = GlobalVariable.MobileNumber;
console.log(this.examResult);

if(this.answers > 5){
this.isFived = true;
this.isFifteen = false;
}
if(this.answers > 15){
  this.isFifteen = true;
  this.isFived = false;
  }

    this.service.AddExamResult(this.examResult).subscribe((resp) => {
    
      if (resp == 'Failed') {
   
        // alert('Something went wrong! Please try again.')
        this.alertWithError();
        
      } else {
    
        this.SetMessage();
      
      }
    }); 
   
    this.service. GetAttemptHistoryById(this.UniqID)
    .subscribe((result) => {
      console.log(result);
      this.attemptCount = result.Count;    
    this.attemptHistory = result;


    if (result.Count == 0) {
      this.attemptHistory.Unique_Code = this.UniqID;
      this.attemptHistory.Count = 1;
      console.log(this.attemptHistory);
      this.service.AddAttemptHistory(this.attemptHistory).subscribe((resp) => {
        if (resp == "Failed") {
          // alert("Something went wrong! Please try again.");
          this.alertWithError();
        } else {
          // alert("Add succesfully.");
          // this.router.navigateByUrl("/Result-Certi/" + this.UserId);
          
        }
      });

    }
    // else if(result.Count == 100000000){
    //   // alert('Your Attempts Finished');
    //   this.alertWithMsg();
    // } 
    else{
  

    this.attemptHistory.Count = this.attemptHistory.Count + 1;
    this.service.UpdateAttemptHistory(this.attemptHistory).subscribe((resp) => {
      if (resp == "Failed") {
        // alert("Something went wrong! Please try again.");
        this.alertWithError();
      } else {
        // alert("update Succesfully.");
        // this.router.navigateByUrl("/Result-Certi/" + this.UserId);
      }
    });
    console.log(this.attemptHistory);
  }
  
    this.service. GetAttemptHistoryById(this.UniqID)
    .subscribe((result) => {
      console.log(result);
      this.attemptcount = result.Count;
      this.attemptcount = 3 - this.attemptcount; 
      if(result.Count == 3){
        this.LastCount = true;
        this.isCount = false;

      }

    });
    });



    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    // this.http
    //   .post<ExamResult>(
    //     GlobalVariable.SERVICE_API_URL + 'StudentInfo/SendEmail',
    //     this.examResult,
    //     httpOptions,
    //   )
    //   .subscribe((resp) => {
    //        alert('Successfully Send...');   
    //         // this.router.navigate(['/thank-you']);
    //   })


  }

  SetMessage(){
   
    if(this.answers >=0 && this.answers <= 14){
      this.message =  "" ;
      this.isOne = true;
    }
    else if(this.answers > 14 && this.answers <= 17){
      // this.message = "Thank you for participating in this exam. Your score is lower than average score in this field. For your requirement we recommended our advance pressure vessel design courses. ";
      this.isTwo = true;
    }
    else if(this.answers > 17 && this.answers <= 20){
      // this.message = "Thank you for participating in this exam. Your score is not good engough for this field. For your requirement we recommended our advance pressure vessel design courses.";
      this.isThree = true;
    }
   
  }
  onNextresult(){
    this.router.navigate(['/video']);
}
time() {
  //var presentTime = document.getElementById('timer').innerHTML;
  // timeArray : any[]
  // CourseList
   this.TimeCheck = this.clock.split(/[:]+/);
  var m = this.TimeCheck[0];
  var s = this.checkSecond((this.TimeCheck[1] - 1));
  if(s==59){m=m-1}
  if((m + '').length == 1){
    m = '0' + m;
  }
    //if(m<0){alert('timer completed')}
  
  if(m == -1 && this.isValid == false){
    this.isValid = true;
    //this.submit();
    m = '00';
    s = '00';
    this.minute = "00"
    //alert("end");
    //this.gameover = true    
    this.submit();
     //return;
    // this.currentIndex = 2; 
   
  }
  else if(this.isValid)
  {
    m = '00';
    s = '00';
  }
  this.clock = m + ":" + s;
  //setTimeout(this.startTimer, 1000);
}
   startTimer() {
  
  
}



 checkSecond(sec) {
  if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
  if (sec < 0) {sec = "59"};
  return sec;
}

// Data(){
//   if(this.minute == "00"){
//     alert("Hello Team");
//   }
// }

  
  startQuiz() {
    
    this.gameover = false
    this.currentIndex = 0
    this.currentQuestionSet = this.questions[this.currentIndex]
    this.start = false
    // alert(this.currentIndex)
    this.flag = false
    counter.subscribe(() => {
      this.time();
    });
    // var presentTime = document.getElementById('timer')
    // console.log(presentTime);
//     if(startTimer.m<0){
// alert("Time End");
//     }
  }
  sendEmail(){
this.termsinfo.Response="Yes";
this.termsinfo.IPAddress=this.ipAddress;
this.termsinfo.Device=this.deviceInfo.deviceType;
this.termsinfo.Email=GlobalVariable.Email;
this.termsinfo.FirstName=GlobalVariable.FirstName;
this.termsinfo.LastName=GlobalVariable.Lastname;
this.termsinfo.OTP= GlobalVariable.OTP;
this.termsinfo.UserInfo= this.deviceInfo.userAgent;
console.log(this.termsinfo)
this.service.AddTermsInfo(this.termsinfo).subscribe((resp) => {
  console.log(resp)
  if (resp == 'Failed') {
    // alert('Something went wrong! Please try again.')
    //this.alertWithSuccess();
   }
});

this.alertWithSuccess();
this.service.SendTermsEmail(this.termsinfo).subscribe((resp) => {
  console.log(resp)
    //  this.alertWithSuccess();
     
//alert("1")

     //this.router.navigateByUrl("http://www.expressengineeringsolutions.in");
    if (resp == "Failed") {
      // alert("Something went wrong! Please try again.");
    } else {
      
    }
  });
  setTimeout(()=>{                           //<<<---using ()=> syntax
    window.location.href = "http://www.expressengineeringsolutions.in/";
}, 5000);
  //this.router.navigateByUrl("http://www.expressengineeringsolutions.in/");
}
  ngOnInit() {
   
    this.getIP();  

    // this.service.GetUserScoreList().subscribe((resp) => {
    //   console.log(resp);
    //   for (let result of resp) {
    //     this.list.push(result);
    //   }
    
    // //   this.list = this.list.filter((x) => x.Id == this.examResult.UserId);
    // //   console.log(this.list);

    //  });

    this.service.GetUserList().subscribe((result) => {
      console.log(result);
      let list = result.data.filter(
        (result) => result.Id == this.UserId)
      for (let data of list) {
        this.UserList.push(data);
      }
      console.log(this.UserList);
      
    });

  }

  View(id) {
    try {
      this.router.navigateByUrl("/Result-Certi/" + id);
    } catch (error) {
      alert("Edit-" + error);
    }
  }

  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{
      this.ipAddress=res.ip;  
      console.log(this.ipAddress)
    });  
  } 






}
