<div class="container">
         <div class="row row-head">
            <div class="col-md-12 col-xs-12" style="text-align: center;"> 
            <div class="breadcrumbs-area">
                <h2 style="text-align: center; font-size: 24px; margin-bottom: 2px;">Finishing in</h2>
                <div class="time"> <span id="" class="clock" style="font-size: 19px;  font-weight: 600;">{{clock}}</span></div>
            </div>
            </div>
            <div class="col-md-12 col-xs-12" style="text-align: center;"> 
                <!-- <video controls autoplay src="../../assets/images/video.mp4"> -->
                    <!-- <video width="800" height="400"  autoplay autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true" autoplay> -->
                        <video class="video-size" width="800" height="400"  autoplay="autoplay"  oncanplay="this.play()">
                        <source src="../../assets/images/ASME.mp4" type="video/mp4">
                        <source src="../../assets/images/ASME.ogg" type="video/ogg">
                        Your browser does not support the video tag.
                      </video>
            </div>
        </div> 
    </div>