<div class="row">
    <div class="col-9">
      <div *ngIf="title" 
        [class]="options.titleClass" 
        [attr.aria-label]="title"
        class="custom-title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
        class="custom-message"
        [class]="options.messageClass" [innerHTML]="message">
      </div>
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
        class="custom-message"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>
    <div class="col-3 text-right">
      <!-- <a *ngIf="!options.closeButton" class="btn btn-pink btn-sm" (click)="action($event)">
        {{ undoString }}
      </a> -->
      <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm toast-close-button">
        &times;
      </a>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>