<div class="section over-hide z-bigger">
  <input class="checkbox" type="checkbox" name="general" id="general">
  <div class="section over-hide z-bigger">
    <div class="container pb-5">
      <div class="row justify-content-center pb-5">
        <div class="col-12 pt-5">
          <p class="mb-4 pb-2">Would You like to download our brochures.</p>
        </div>
        <div class="col-12 pb-5">
          <input class="checkbox-tools" type="radio" name="tools" value="Yes" id="tool-1" (click)="yes()">
          <label class="for-checkbox-tools" for="tool-1">
            <i class='uil uil-line-alt'></i>
            Yes
          </label>
          <input class="checkbox-tools" type="radio" name="tools" value="No" id="tool-2" (click)="no()">
          <label class="for-checkbox-tools" for="tool-2">
            <i class='uil uil-vector-square'></i>
            No
          </label>

        </div>
        <div *ngIf="isVisible">
          <p class="links">
            <a class="footer-pdf"
              href="http://www.expressengineeringsolutions.in/wp-content/uploads/2021/01/BPVC-Training-.pdf"
              target="_blank" download=""><i class="fa fa-download"></i>Pressure vessel</a>
          </p>
          <p class="links">
            <a class="footer-pdf"
              href="http://www.expressengineeringsolutions.in/wp-content/uploads/2021/01/PvElite-Training.pdf"
              target="_blank" download=""><i class="fa fa-download"></i>PvElite Training</a>
          </p>
          <p class="links">
            <a class="footer-pdf"
              href="http://www.expressengineeringsolutions.in/wp-content/uploads/2021/01/Heat-Exchanger-Training.pdf"
              target="_blank" download=""><i class="fa fa-download"></i>Heat Exchanger Training</a>
          </p>
        </div>

      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4" style="text-align: center;">
        <button type="button" class="btn-fill-lg start-btn btnsubmit " (click)="onNextresult()">Next</button>
        <br>
        <br>
        <div class="blink">
        <h2 class="steps green blink-text">You are just 2 steps away from getting certificate </h2>
      </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</div>