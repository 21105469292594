export class User {
  constructor(
    public lane1: string,
    public lane2: string,
    public city: string,
    public state: string,
    public pincode: number,

    public fname: string,
    public lname: string,
    public gender: string,
    public month: string,
    public day: string,
    public year: string,
    public maritalstatus: string,
    public religion: string,
    public cast: string,
    public subcast: string,
    public createdby: string,
    public email: string,
    public password: string,
    public confirmpass: string,
    public mobile: number,
    public altmobile: number,
    public gothra: string,
    public moonsign: string,
    public horos: string,
    public manglik: string,
    public birthplace: string,
    public birthtime: string,
    public height: string,
    public weight: string,
    public blood: string,
    public complaxion: string,
    public physicalstatus: string,
    public bodytype: string,
    public diet: string,
    public smoke: string,
    public drink: string,
    public subscribe: boolean
  ) {}
}
