import { Component, OnInit } from "@angular/core";
import { ExamResult, OnlineExam, ResultCerti, AttemptHistory } from "../user1";
import { CommonserviceService } from "../commonservice.service";
import { Routes, RouterModule } from "@angular/router";
import { ActivatedRoute, Router } from "@angular/router";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas'; 


@Component({
  selector: 'app-result-certi',
  templateUrl: './result-certi.component.html',
  styleUrls: ['./result-certi.component.css']
})
export class ResultCertiComponent implements OnInit {
  UniqID: any;
  per: any;
  Id: any;
  name = "Angular";
  domains = [];
  isVisible: boolean = false;

  isEditable: boolean;
  resultCerti: ResultCerti;
  // addCourses: AddCourses;


  MobileNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidFormSubmitted = false;

  numberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidNoSubmitted = false;
  EmailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";

  constructor(
    private service: CommonserviceService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    
     this.resultCerti = new ResultCerti();
     this.resultCerti.ExamResult = new ExamResult();
     this.resultCerti.OnlineExamForm = new OnlineExam();
     this.resultCerti.AttemptHistory = new AttemptHistory();

    this.route.params.subscribe((params) => {
      this.Id = params.id;
      //  alert(this.Id);
      this.service.GetResultCertiById(this.Id).subscribe((result) => {
        // alert(12);
        this.resultCerti = result;
        console.log(this.resultCerti.ExamResult);
   this.per = parseInt(this.resultCerti.ExamResult.correct)/50;
   this.per = this.per*100;
  // console.log(this.per);
  // alert(this.per);
        console.log(this.resultCerti);
      });

    //   this.service. GetAttemptHistoryById(this.UniqID)
    //   .subscribe((result) => {
    //   this.attemptHistory = result;
    //   console.log(this.attemptHistory);
    // });


    });
  }
  printPage() {
    window.print();
  }
  ngOnInit() {
    this.UniqID = localStorage.getItem('UniqCode');
    // this.service.GetMigrationList().subscribe((result) => {
    //   this.migration = result.map((item) => {
    //     return item;
    //   });
    // });
  }
  pdfpage(){
    
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  

      // var imgWidth = 190;   
      // var pageHeight = 9900;  
      // var imgHeight = canvas.height * imgWidth / canvas.width;   
      // var heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4', true); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceipt.pdf'); // Generated PDF   
    });  
  }
}



