
<div class="section over-hide z-bigger">
  <input class="checkbox" type="checkbox" name="general" id="general">
  <div class="section over-hide z-bigger">
    <div class="container pb-5">
      <div class="row justify-content-center pb-5">
        <!-- <div class="col-12 pt-5">
          <p class="mb-4 pb-2">Would You like to download our brochures.</p>
        </div> -->
        <!-- <div class="col-12 pb-5">
          <input class="checkbox-tools" type="radio" name="tools" value="Yes" id="tool-1" (click)="yes()">
          <label class="for-checkbox-tools" for="tool-1">
            <i class='uil uil-line-alt'></i>
            Yes
          </label>
          <input class="checkbox-tools" type="radio" name="tools" value="No" id="tool-2" (click)="no()">
          <label class="for-checkbox-tools" for="tool-2">
            <i class='uil uil-vector-square'></i>
            No
          </label>

        </div> -->
        
        <div class="col-12 pt-5">
          <p class="mb-6 pb-2" style="color:#1882B4;font-size: 23px;">Courses interested in ? </p>
          <div class="blink">
          <h2 class="steps green blink-text">You are just 1 steps away from getting certificate </h2>
        </div>
        </div>
        <div class="col-0 pt-5 mb-1"  ></div>
          <div class="col-8 pt-5 mb-11" >
          <form [formGroup]="form" (ngSubmit)="submit()" class="form-list">
            <div class="form-group">
              <label for="website">Courses:</label>
              <div *ngFor="let web of websiteList">
                <label class="labels labelmar">
                  <input type="checkbox" class="select checkmargin" [value]="web.name" (change)="onCheckboxChange($event)" />
                  {{web.name}}
                </label>
              </div>
            </div>
            <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Submit</button>&nbsp;&nbsp;
            <!-- <button class="btn btn-primary" type="submit" (click)="onNextcourse()">Next</button> -->
           
          </form>
        </div>
        <br>
        
      </div>
    </div>
  </div>
</div>