import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from 'src/app/Globals'
import { Data, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  UserId: any;
  constructor( private router: Router) { 
    // this.UserId = GlobalVariable.UserExamId;
    this.UserId = localStorage.getItem('dataSource');
  }
  onNextresult(){
    this.router.navigateByUrl("/Certificate/" + this.UserId);
}
  ngOnInit() {
  }

  simpleAlert(){
    Swal.fire('Hello world!');
  }
  
  alertWithSuccess(){
    Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
  
  confirmBox(){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }
  
}
