import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { CommonserviceService } from '../commonservice.service'
import { OnlineExam,RegisterOTP } from '../user1'
import { GlobalVariable } from 'src/app/Globals'
import {timer } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
const counter = timer(0, 1000);
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OTPComponent implements OnInit {
  registerOTP:RegisterOTP
  Otp:any;
  mail: any;
  clock: any
  isResend: boolean;
  isValid: boolean;
  isTimeOut: boolean;
  TimeCheck: any[]
  minute: any;
  timer:'00:59';

  constructor( private router: Router, private http: HttpClient,private service: CommonserviceService) {
    this.registerOTP = new RegisterOTP();
    this.mail = localStorage.getItem('email');
    this.clock = "00:58";
    this.isValid = false;
    
   }

  ngOnInit() {
    counter.subscribe(() => {
      this.time();
    });
  }
  onNext(){
    this.router.navigate(['/exam']);
    
}
alertWithSuccess(){
  Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
}
alertWithSuccess1(){
  Swal.fire('Oops...', 'Something Went Wrong! Please try again.', 'error')
}
alertWithError(){
  Swal.fire('','Invalid OTP', 'error')
}
simpleAlert(){
  Swal.fire('You have already register with This Email ID.');
}
onOtpChange(event){
  if(event.length == 6)
  {
   //console.log(event)
   this.Otp = event;
  }
}
otpController(event,next,prev, index){


  if(index == 6) {
    //console.log("submit")
  }
  if(event.target.value.length < 1 && prev){
    prev.setFocus()
  }
  else if(next && event.target.value.length>0){
    next.setFocus();
  }
  else {
   return 0;
  } 
}
time() {
  this.TimeCheck = this.clock.split(/[:]+/);
  var m = this.TimeCheck[0];
  var s = this.checkSecond((this.TimeCheck[1] - 1));
  if(s==59){m=m-1}
  if((m + '').length == 1){
    m = '0' + m;
  }
  
  if(m == -1 && this.isValid == false){
    this.isValid = true;
    m = '00';
    s = '00';
    this.minute = "00" 
    this.isTimeOut = true;
  }
  else if(this.isValid)
  {
    m = '00';
    s = '00';
  }
  this.clock = m + ":" + s;
}  

checkSecond(sec) {
  if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
  if (sec < 0) {sec = "59"};
  return sec;
}

Verify() {

  // localStorage.setItem('dataSource', resp);
  this.registerOTP.Mobile = this.mail;
  this.service.GetOtpDetailsByMobileNumber(this.registerOTP.Mobile).subscribe((result) => {
    console.log(result)
    console.log(this.Otp,this.registerOTP.Mobile)
    if(result.data.mobile == this.registerOTP.Mobile) {
      // alert(1)
      if( result.data.otp == this.Otp) {
        // alert(2)
        if( result.data.status == "Send"){  

      // alert(3)
      this.service.UpdateOtpStatus(this.registerOTP.Mobile,this.Otp).subscribe((result) => {
        if(result.success){
           this.router.navigateByUrl("/exam");
        }
        
      });
    }
  }
}
    else {
      // alert("Invalid OTP");
      this.alertWithError();
    }
  });
}

ResendOtp(){

this.registerOTP.Mobile = this.mail;
    
  this.TimeCheck = this.clock.split(/[:]+/);
  var m = this.TimeCheck[0];
  var s = this.checkSecond((this.TimeCheck[1] - 1));
  if(s==59){m=m-1}
  if((m + '').length == 1){
    m = '0' + m;
  }
  
  if(m == -1 && this.isValid == false){
    this.isValid = true;
    //this.submit();
    m = '00';
    s = '00';
    this.minute = "00" 
  }
  else if(this.isValid)
  {
    m = '00';
    s = '00';
  }
  this.clock = m + ":" + s;

  let num = Math.floor(100000 + Math.random() * 900000);
  this.registerOTP.OTP = (num).toString();
  this.service.ResendOtp(this.mail,this.registerOTP.OTP).subscribe((result) => {
    if(result == "Success"){
      // alert("Saved Successfully");
      this.alertWithSuccess();
      // this.isTimeOut = false;
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
      this.http
        .post<RegisterOTP>(
          GlobalVariable.SERVICE_API_URL + 'StudentInfo/SendOTP',
          this.registerOTP,
          httpOptions,
        )
        .subscribe((resp) => {
             //alert('Successfully Send...');   
        })
    }
    else {
      // alert("Invalid OTP");
      this.alertWithError();
    } 
  });
}

}
