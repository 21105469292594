import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { ExamResult } from '../user1'
import { AttemptHistory } from '../user1'

import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { Data, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
//import { startTimer } from '../../assets/js/timerCount';
// declare const startTimer: any;
import {timer } from 'rxjs';
declare let $: any;
const counter = timer(0, 1000);
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  status = 'start'
  @ViewChild('countdown') counter: CountdownComponent
  examResult: ExamResult
  attemptHistory: AttemptHistory
  list: any[];
  data: any[];
  TimeCheck: any[]
  minute: any;
  message:string;
  isValid:boolean;
  // isPassed: boolean;
    isFived: boolean;
    isFifteen:boolean;
    isOne:boolean=false;
    isTwo:boolean=false;
    isThree:boolean=false;
  attemptcount: any;
  timer:'00:30';
  public clock;
  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router) { 
    this.clock = "01:13";
    this.isValid = false;
  }
  time() {
    //var presentTime = document.getElementById('timer').innerHTML;
    // timeArray : any[]
    // CourseList
     this.TimeCheck = this.clock.split(/[:]+/);
    var m = this.TimeCheck[0];
    var s = this.checkSecond((this.TimeCheck[1] - 1));
    if(s==59){m=m-1}
    if((m + '').length == 1){
      m = '0' + m;
    }
      //if(m<0){alert('timer completed')}
    // alert(this.isValid)
    if(m == -1 && this.isValid == false){
      this.isValid = true;
      //this.submit();
      m = '00';
      s = '00';
      this.minute = "00"
      this.router.navigateByUrl("/brochures");
      //alert("end");
      //this.gameover = true    
      // this.submit();
       //return;
      // this.currentIndex = 2; 
     
    }
    else if(this.isValid)
    {
      m = '00';
      s = '00';
    }
    this.clock = m + ":" + s;
    //setTimeout(this.startTimer, 1000);
  }
   checkSecond(sec) {
    if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
    if (sec < 0) {sec = "59"};
    return sec;
  }
  
//   submit(){
// alert(1)
//   }
  ngOnInit(): void {
    counter.subscribe(() => {
      this.time();
    });
    
  }

}
