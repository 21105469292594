<div class="main-content">
  <div class="container-fluid">
      <div class="row">
          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-12">
              <div class="card">
                  <div class="card-header card-header-danger">
                      <h4 class="card-title">Report</h4>
                      <p class="card-category"></p>
                  </div>
                  <div class="card-body">

                      <div class="row">

                          <div class="col-md-12">
                            <form class="mg-b-20">
                                <div class="row gutters-8">
                                    
                                  <div class="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                                      <input name="searchbyName" type="text" placeholder="Search by First Name ..." [(ngModel)]="SearchText" class="form-control">
                                  </div>
                                  <div class="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                                      <button type="submit" class="fw-btn-fill btn-gradient-yellow" (click)="Serach()">SEARCH</button>
                                  </div>
                                  <div class="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                                    <button type="submit" class="fw-btn-fill btn-gradient-yellow" (click)="Reset()">RESET</button>
                                </div>
                              </div>
                              </form>

                              <div class="table-responsive">
                                  <table class="table table-bordered">
                                      <thead>
                                          <tr>
                                            <th>Email</th>
                                            <th>Name</th>
                                            <th>Response</th>
                                            <th>IP Address</th>
                                            <th>OTP</th>
                                            <th>User Info</th>
                                       
                                            
                                            <!-- <th>CreatedBy</th>
                                            <th>CreatedDate</th> -->
                                          </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let i of applicationdetailslist">
                                          <td>{{i.Email}}</td>
                                          <td>{{i.FirstName}}</td>

                                          <td>{{i.Response}}</td>
                                          <td>{{i.IPAddress}}</td>
                                          <td>{{i.OTP}}</td>
                                          <td>{{i.UserInfo}}</td>
                                          
                                       

                                          

                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>


                      <div class="row">
                          <div class="col-md-12">

                          </div>
                      </div>


                  </div>
              </div>
          </div>
      </div>
  </div>
</div>