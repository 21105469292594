import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { ExamResult } from '../user1'
import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { Data, Router } from '@angular/router';

@Component({
  selector: 'app-exam-result-list',
  templateUrl: './exam-result-list.component.html',
  styleUrls: ['./exam-result-list.component.css']
})
export class ExamResultListComponent implements OnInit {
List: any[];
SearchText: string;
  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router) {

    this.List = [];

   }


   Serach() {
    this.List = [];
    this.service.GetUserScoreList().subscribe((resp) => {
      for (let result of resp) {
        this.List.push(result);
      }
      this.List = this.List.filter(x => x.FName.toLowerCase().includes(this.SearchText.toLowerCase()));
    });

  }

   Reset(){
    this.List = [];
    this.service.GetUserScoreList().subscribe((resp) => {
      //console.log(resp);
      for (let result of resp) {
        this.List.push(result);
      } 
    });
  }


  ngOnInit() {
    this.service.GetUserScoreList().subscribe((resp) => {
      //console.log(resp);
      for (let result of resp) {
        this.List.push(result);
      } 
    });

  }
  certi(Id)
  {
    try {
      this.router.navigateByUrl("/Certificate/" + Id);
    } catch (error) {
      alert("certi-" + error);
    } 
  }
  marksheet(Id){
    try {
      this.router.navigateByUrl("/Result-Certi/" + Id);
    } catch (error) {
      alert("marksheet-" + error);
    }
  }


}
