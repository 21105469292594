import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { PreloadAllModules } from '@angular/router'

import { RegisterComponent } from './register/register.component'

import { OnlineExamComponent } from './online-exam/online-exam.component'
import { QuizComponent } from './quiz/quiz.component'
// import { ApplyQuizComponent } from './apply-quiz/apply-quiz.component'

import { ThankYouComponent } from './thank-you/thank-you.component'
import { ResultCertiComponent } from './result-certi/result-certi.component';
import { CertificateComponent } from './certificate/certificate.component';
import { ExamResultListComponent } from './exam-result-list/exam-result-list.component';
import { OTPComponent } from './otp/otp.component';
import { CoursesComponent } from './courses/courses.component';
import { BrochuresComponent } from './brochures/brochures.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { VideoComponent } from './video/video.component';
import { TermsConditionsReportComponent } from './terms-conditions-report/terms-conditions-report.component';
const routes: Routes = [
  // { path : '**', redirectTo:'/Online-Exam', pathMatch: 'full'},
  {
    path: '',
    redirectTo: 'Online-Exam',
    pathMatch: 'full',
  },
  // { path : '', component:OnlineExamComponent},

  { path: 'register', component: RegisterComponent },


  {
    path: 'Online-Exam',
    component: OnlineExamComponent,
  },
  {
    path: 'exam',
    component: QuizComponent,
  },
      //    {
      //   path: 'apply-quiz',
      //   component: ApplyQuizComponent,
      // },
    
      {
        path: 'thank-you',
        component: ThankYouComponent,
      },
      {
        path: 'Result-Certi/:id',
        component: ResultCertiComponent,
      },
      {
        path: 'Certificate/:id',
        component: CertificateComponent,
      },
      {
        path: 'Exam-Result-List',
        component: ExamResultListComponent,
      },
      {
        path: 'otp',
        component: OTPComponent,
      },
      {
        path: 'courses',
        component: CoursesComponent,
      },
      {
        path: 'brochures',
        component: BrochuresComponent,
      },
      {
        path: 'thankyou',
        component: ThankYouComponent,
      },
      {
        path: 'video',
        component: VideoComponent,
    },
    {
      path: 'terms-conditions-report',
      component: TermsConditionsReportComponent,
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
